import React, {FunctionComponent} from "react";
import {LocalizeContextProps, withLocalize} from "react-localize-redux";
import {BooleanInputField} from "../../../../common/components/input-fields/BooleanInputField";
import DropdownInputField from "../../../../common/components/input-fields/DropdownInputField";
import InputField from "../../../../common/components/input-fields/InputField";
import {SelectableItem} from "../../../../common/models/SelectableItem";
import SubstatusesInputField from "../../../../common/components/input-fields/SubstatusesInputField";
import {getEnumTranslationKey} from "../../../../common/helpers/getTranslationKey";
import mapEnumToSelectableItems from "../../../../common/helpers/mapEnumToSelectableItems";
import DateTimeInputField, {
    DateTimeInputFieldKind
} from "../../../../common/components/input-fields/DateTimeInputField";
import {isValueSet} from "../../../../common/helpers/isValueSet";
import CustomerAdvisorsInputField from "../../../../common/components/input-fields/CustomerAdvisorsInputField";
import CustomersFilterPreview from "./CustomersFilterPreview";
import {MarketingListCustomersFilter} from "../../../model/MarketingListCustomersFilter";
import CustomersFilter from "./CustomersFilter";
import StringCollectionInputField from "../../../../common/components/input-fields/StringCollectionInputField";
import {MultiSelect} from "../../../../common/components/MultiSelect";
import {MarketingListCustomerStatus} from "../../../model/MarketingListCustomerStatus";
import {ApplicationStatus} from "../../../../common/models/ApplicationStatus";
import NumberInputField, {NumberInputFieldKind} from "../../../../common/components/input-fields/NumberInputField";
import {ClawbackFilterType} from "../../../model/ClawbackFilterType";
import {TimeUnit} from "../../../../common/models/TimeUnit";
import {numberKindToTimeUnit, timeUnitToNumberKind} from "../../../../common/helpers/numberInputFieldsKindMappers";

export interface CustomersFilterProps extends LocalizeContextProps {
    editMode: boolean;
    filter?: MarketingListCustomersFilter;
    onChange: (filter: Partial<MarketingListCustomersFilter>) => void;
}

const CustomersFilterContainer: FunctionComponent<CustomersFilterProps> = (props) =>
    props.editMode
        ? <CustomersFilterPreview {...props} />
        : <CustomersFilter {...props} />

export const renderCustomerAdvisorsFilter = (props: CustomersFilterProps) => {
    const customerAdvisors = getFilterProp<number[]>('customerAdvisors', props) || [];
    const onCustomerAdvisorsChanged = (customerAdvisors: number[]) => props.onChange({ customerAdvisors });
    return (
        <div>
            <CustomerAdvisorsInputField
                descriptionKey="CUSTOMER_ADVISOR"
                editMode={true}
                className={customerAdvisors.length ? 'filter-active' : ''}
                customerAdvisorIds={customerAdvisors}
                onCustomerAdvisorsChanged={onCustomerAdvisorsChanged}
                placeholder={props.translate('HEADER.FILTER_CUSTOMER_ADVISORS').toString()}
            />
        </div>
    );
}

export const renderLoanStatusFilter = (props: CustomersFilterProps) => (
    renderDropdownInputFieldFilter(
        'lastApplicationStatus',
        'LOAN_STATUS.LABEL',
        mapEnumToSelectableItems(
            ApplicationStatus,
            item => props.translate(getEnumTranslationKey(ApplicationStatus, item, 'LOAN_STATUS', 'ALL')).toString(),
            true
        ),
        props
    )
);

export const renderCustomerStatusesFilter = (props: CustomersFilterProps) => {
    const statuses = getFilterProp<MarketingListCustomerStatus[]>('customerStatuses', props) || [];
    const onCustomerStatusesChanged = (customerStatuses: MarketingListCustomerStatus[]) => props.onChange({ customerStatuses });
    const customerStatusesItems = mapEnumToSelectableItems<number>(
        MarketingListCustomerStatus,
        item => `${props.translate('CUSTOMER_STATUS.PREFIX').toString()}${props.translate(getEnumTranslationKey(MarketingListCustomerStatus, item, 'CUSTOMER_STATUS')).toString()}`,
        false,
        true
    );

    return (
        <div>
            <MultiSelect
                descriptionKey="CUSTOMER_STATUS.LABEL"
                className={statuses.length ? 'filter-active' : ''}
                selectedItems={customerStatusesItems.filter(item => statuses.some(status => item.id === status))}
                items={customerStatusesItems}
                onSelectionChanged={items => onCustomerStatusesChanged(items.map(item => item.id as number))}
                placeHolder={props.translate('FILTER_CUSTOMER_STATUS').toString()}
            />
        </div>
    );
}

export const renderLastStatusChangeFromFilter = (props: CustomersFilterProps) =>
    renderDateTimeInputFieldFilter('lastStatusUpdateFrom', 'CAMPAIGNS_VIEW.LAST_STATUS_UPDATE_FROM', props);

export const renderLastStatusChangeToFilter = (props: CustomersFilterProps) =>
    renderDateTimeInputFieldFilter('lastStatusUpdateTo', 'CAMPAIGNS_VIEW.LAST_STATUS_UPDATE_TO', props);

export const renderLastStatusChangeFilter = (props: CustomersFilterProps) =>
    renderNumberInputFieldFilter('lastStatusUpdate', 'MARKETING_LISTS_VIEW.LAST_STATUS_UPDATE_SINCE', props, new KindConfig<TimeUnit>('lastStatusUpdateTimeUnit', [TimeUnit.Months, TimeUnit.Days], timeUnitToNumberKind, numberKindToTimeUnit));

export const renderLastContactFilter = (props: CustomersFilterProps) =>
    renderNumberInputFieldFilter('lastContact', 'MARKETING_LISTS_VIEW.LAST_CONTACT_SINCE', props, new KindConfig<TimeUnit>('lastContactTimeUnit', [TimeUnit.Months, TimeUnit.Days], timeUnitToNumberKind, numberKindToTimeUnit));

export const renderMinimumGrossYearlyIncomeFilter = (props: CustomersFilterProps) =>
    renderNumberInputFieldFilter('minimumGrossYearlyIncome', 'MARKETING_LISTS_VIEW.MINIMUM_GROSS_YEARLY_INCOME', props, NumberInputFieldKind.MoneyPerYear);

export const renderPropertyEstimatedValueFilter = (props: CustomersFilterProps) => {
    if (!props.filter?.hasProperty) {
        return null;
    }
    return renderNumberInputFieldFilter('propertyEstimatedValue', 'MARKETING_LISTS_VIEW.PROPERTY_ESTIMATED_VALUE', props, NumberInputFieldKind.Money);
}
export const renderPropertyAreaMarketScoreFilter = (props: CustomersFilterProps) => {
    if (!props.filter?.hasProperty) {
        return null;
    }
    return renderNumberInputFieldFilter('propertyAreaMarketScore', 'MARKETING_LISTS_VIEW.PROPERTY_AREA_MARKET_SCORE', props, NumberInputFieldKind.NumberOf);
}
    
export const renderPaidAmountFromFilter = (props: CustomersFilterProps) => {
    if (props.filter?.lastApplicationStatus === ApplicationStatus.PAID_AS_LAST || props.filter?.lastApplicationStatus === ApplicationStatus.LATEST_PAID) {
        return renderNumberInputFieldFilter('paidAmountFrom', 'MARKETING_LISTS_VIEW.PAID_AMOUNT_FROM', props, NumberInputFieldKind.Money);
    }
    return null;
}
    
export const renderPaidAmountToFilter = (props: CustomersFilterProps) => {
    if (props.filter?.lastApplicationStatus === ApplicationStatus.PAID_AS_LAST || props.filter?.lastApplicationStatus === ApplicationStatus.LATEST_PAID) {
        return renderNumberInputFieldFilter('paidAmountTo', 'MARKETING_LISTS_VIEW.PAID_AMOUNT_TO', props, NumberInputFieldKind.Money);
    }
    return null;
}

export const renderNominalInterestFromFilter = (props: CustomersFilterProps) => {
    if (props.filter?.lastApplicationStatus === ApplicationStatus.PAID_AS_LAST || props.filter?.lastApplicationStatus === ApplicationStatus.LATEST_PAID) {
        return renderNumberInputFieldFilter('nominalInterestFrom', 'MARKETING_LISTS_VIEW.NOMINAL_INTEREST_FROM', props, NumberInputFieldKind.Percent, 0.01);
    }
    return null;
}

export const renderNominalInterestToFilter = (props: CustomersFilterProps) => {
    if (props.filter?.lastApplicationStatus === ApplicationStatus.PAID_AS_LAST || props.filter?.lastApplicationStatus === ApplicationStatus.LATEST_PAID) {
        return renderNumberInputFieldFilter('nominalInterestTo', 'MARKETING_LISTS_VIEW.NOMINAL_INTEREST_TO', props, NumberInputFieldKind.Percent,0.01);
    }
    return null;
}
export const renderUnsecuredDebtsFromFilter = (props: CustomersFilterProps) =>
    renderNumberInputFieldFilter('unsecuredDebtsFrom', 'MARKETING_LISTS_VIEW.UNSECURED_DEBTS_FROM', props, NumberInputFieldKind.Money)

export const renderUnsecuredDebtsToFilter = (props: CustomersFilterProps) =>
    renderNumberInputFieldFilter('unsecuredDebtsTo', 'MARKETING_LISTS_VIEW.UNSECURED_DEBTS_TO', props, NumberInputFieldKind.Money)

export const renderClawbackFilterType = (props: CustomersFilterProps) => {
    if (props.filter?.lastApplicationStatus === ApplicationStatus.PAID_AS_LAST || props.filter?.lastApplicationStatus === ApplicationStatus.LATEST_PAID) {
        return renderDropdownInputFieldFilter(
            'clawbackFilterType', 
            'MARKETING_LISTS_VIEW.CLAWBACK_FILTER_TYPE', mapEnumToSelectableItems(
                ClawbackFilterType,
                item => props.translate(getEnumTranslationKey(ClawbackFilterType, item, 'CLAWBACK_FILTER_TYPE', 'NONE')).toString(), true, true),
                props);
    }
    return null;
}

export const renderClawbackIssuedFromFilter = (props: CustomersFilterProps) => {
    if (props.filter?.lastApplicationStatus === ApplicationStatus.PAID_AS_LAST || props.filter?.lastApplicationStatus === ApplicationStatus.LATEST_PAID) {
        return renderDateTimeInputFieldFilter('clawbackIssuedFrom', 'MARKETING_LISTS_VIEW.CLAWBACK_ISSUED_FROM', props);
    }
    
    return null;
}

export const renderClawbackIssuedToFilter = (props: CustomersFilterProps) => {
    if (props.filter?.lastApplicationStatus === ApplicationStatus.PAID_AS_LAST || props.filter?.lastApplicationStatus === ApplicationStatus.LATEST_PAID) {
        return renderDateTimeInputFieldFilter('clawbackIssuedTo', 'MARKETING_LISTS_VIEW.CLAWBACK_ISSUED_TO', props);
    }

    return null;
}

export const renderDateTimeInputFieldFilter = (name: keyof MarketingListCustomersFilter, descriptionKey: string, props: CustomersFilterProps) => {
    const value = getFilterProp<string | null>(name, props);
    if (!props.editMode && !isValueSet(value)) {
        return null;
    }

    const onValueChanged = (value: string | null | undefined) => props.onChange({ [name]: value });
    return (
        <div>
            <DateTimeInputField
                name={name}
                editMode={props.editMode}
                onValueChanged={value => onValueChanged(value ? value.toISOString() : undefined)}
                value={value ? new Date(value as string) : undefined}
                descriptionKey={descriptionKey}
                kind={DateTimeInputFieldKind.Date}
            />
        </div>
    )
}
export function renderNumberInputFieldFilter<Unit = never>(name: keyof MarketingListCustomersFilter, descriptionKey: string, props: CustomersFilterProps, kindConfig?: NumberInputFieldKind | KindConfig<Unit>, step?: number) {
    const value = getFilterProp<number | null>(name, props);
    if (!props.editMode && !isValueSet(value)) {
        return null;
    }

    const onValueChanged = (value: number | null | undefined) => props.onChange({ [name]: value });
    const onKindChanged = (kind: NumberInputFieldKind) => {
        if (KindConfig.isKindConfig<Unit>(kindConfig)) {
            props.onChange({[kindConfig.name]: kindConfig.getUnit(kind)});
        }
    }
    
    const getKind = () => {
        if (KindConfig.isKindConfig<Unit>(kindConfig)) {
            return kindConfig.getKind(getFilterProp(kindConfig.name, props));
        }
        return kindConfig;
    }
    
    const getKinds = () => {
        if (KindConfig.isKindConfig<Unit>(kindConfig)) {
            return kindConfig.getKinds();
        }
    }
    
    return (
        <div>
            <NumberInputField
                name={name}
                editMode={props.editMode}
                nullable={true}
                value={value}
                descriptionKey={descriptionKey}
                kind={getKind()}
                kinds={getKinds()}
                readonlyPrecision={0}
                step={step}
                onValueChanged={onValueChanged}
                onKindChanged={onKindChanged}
            />
        </div>
    );
};

export const renderBooleanInputFieldFilter = (name: keyof MarketingListCustomersFilter, descriptionKey: string, props: CustomersFilterProps) => {
    const value = getFilterProp<boolean>(name, props);
    if (!props.editMode && !isValueSet(value)) {
        return null;
    }

    const onValueChanged = (value: boolean | undefined) => props.onChange({ [name]: value });
    return (
        <div>
            <BooleanInputField
                name={name}
                editMode={props.editMode}
                onValueChanged={onValueChanged}
                value={value}
                descriptionKey={descriptionKey}
            />
        </div>
    )
}

export const renderSubstatusesInputFieldFilter = (name: keyof MarketingListCustomersFilter, labelKey: string, props: CustomersFilterProps) => {
    const value = getFilterProp<number[]>(name, props) || [];
    if (!props.editMode && (!value || !value.length)) {
        return null;
    }

    const onValueChanged = (value: number[]) => props.onChange({ [name]: value });
    if (props.editMode) {
        return (
            <div>
                <SubstatusesInputField
                    descriptionKey={labelKey}
                    editMode={true}
                    className={value.length ? 'filter-active' : ''}
                    substatusIds={value}
                    onSubstatusesChanged={onValueChanged}
                    placeholderKey={labelKey}
                />
            </div>
        )
    } else {
        return (
            <div>
                <InputField descriptionKey={labelKey} className="substatuses-input-field-filter">
                    <SubstatusesInputField substatusIds={value} />
                </InputField>
            </div>
        )
    }
}

export const renderDropdownInputFieldFilter = <T extends unknown>(name: keyof MarketingListCustomersFilter, descriptionKey: string, items: SelectableItem<number | string>[], props: CustomersFilterProps) => {
    const value = getFilterProp<T>(name, props);
    if (!props.editMode && !isValueSet(value)) {
        return null;
    }
    const onValueChanged = (value: T | undefined) => props.onChange({ [name]: value });

    return (
        <div>
            <DropdownInputField
                className={isValueSet(value as any) ? 'filter-active' : ''}
                descriptionKey={descriptionKey}
                editMode={props.editMode}
                name={name}
                value={value as any}
                onValueChanged={value => onValueChanged(value as T | undefined)}
                items={items}
            />
        </div>
    )
}

export const renderStringCollectionInputFilter = (name: keyof MarketingListCustomersFilter, labelKey: string, props: CustomersFilterProps) => {
    const value = getFilterProp<string[]>(name, props) || [];
    if (!props.editMode && (!value || !value.length)) {
        return null;
    }

    return (
        <StringCollectionInputField
            value={value}
            name={name}
            description={labelKey}
            edit={props.editMode}
            className={value.length ? 'filter-active' : ''}
            onValueChange={value => props.onChange({ [name]: value })}
        />
    )
}

export const getFilterProp = <T extends unknown>(name: keyof MarketingListCustomersFilter, props: CustomersFilterProps): T | null => {
    if (props.filter && name in props.filter) {
        return props.filter[name] as T;
    }

    return null;
}

class KindConfig<Unit> {
    units?: Unit[];
    name: keyof MarketingListCustomersFilter;
    getter: (unit?: Unit | null) => NumberInputFieldKind | undefined;
    setter: (kind?: NumberInputFieldKind | null) => Unit | undefined;

    constructor(
        name: keyof MarketingListCustomersFilter,
        units: Unit[],
        getter: (unit?: Unit | null) => NumberInputFieldKind | undefined,
        setter: (kind?: NumberInputFieldKind | null) => Unit | undefined
    ) {
        this.units = units;
        this.name = name;
        this.getter = getter;
        this.setter = setter;
    }

    public getKind(unit?: Unit | null) {
        return this.getter(unit);
    }

    public getKinds() {
        return this.units?.map(unit => this.getKind(unit)!);
    }

    public getUnit(kind: NumberInputFieldKind) {
        return this.setter(kind);
    }

    public static isKindConfig<Unit>(kindConfig?: any): kindConfig is KindConfig<Unit> {
        return (
            kindConfig &&
            typeof kindConfig === 'object' &&
            kindConfig.name &&
            Array.isArray(kindConfig.units) &&
            typeof kindConfig.getter === 'function' &&
            typeof kindConfig.setter === 'function'
        );
    }
}

export default withLocalize(CustomersFilterContainer);