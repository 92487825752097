import {FunctionComponent} from "react";
import "./template-action.css";
import AddSubstatus from "./AddSubstatus";
import RemoveSubstatus from "./RemoveSubstatus";
import SendMessage from "./SendMessage";
import ChangeStatus from "./ChangeStatus";
import {TemplatePostAction, TemplatePostActionType} from "../../../models/CommentTemplate";
import {MessageType} from "../../../models/MessageType";
import {LoanStatus} from "../../../models/LoanStatus";

export interface TemplateActionsProps {
    substatusIds?: number[];
    email?: string | null;
    mobileNumber?: string | null;
    loanStatus?: LoanStatus;
} 

interface TemplateActionProps extends TemplateActionsProps {
    action: TemplatePostAction;
}
const TemplateAction: FunctionComponent<TemplateActionProps> = ({
    action,
    substatusIds,
    email,
    mobileNumber,
    loanStatus
}) => {
    switch (action.type) {
        case TemplatePostActionType.ADD_SUBSTATUS:
            return <AddSubstatus current={substatusIds} toAdd={action.data}/>;
        case TemplatePostActionType.REMOVE_SUBSTATUS:
            return <RemoveSubstatus current={substatusIds} toRemove={action.data}/>;
        case TemplatePostActionType.SEND_EMAIL:
            return <SendMessage type={MessageType.Email} template={action.data} email={email}/>
        case TemplatePostActionType.SEND_SMS:
            return <SendMessage type={MessageType.SMS} template={action.data}mobileNumber={mobileNumber}/>
        case TemplatePostActionType.SEND_EMAIL_AND_SMS:
            return <SendMessage template={action.data} email={email} mobileNumber={mobileNumber}/>
        case TemplatePostActionType.DENY_APPLICATION:
            return <ChangeStatus from={loanStatus} to={LoanStatus.Denied}/>
        default:
            return null;
    }
}
export default TemplateAction;