import React, { FunctionComponent } from 'react';
import { Translate } from 'react-localize-redux';
import { Redemption } from '../models/Redemption';
import { faBuilding, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './property-information.css';
import { ConsumerLoanDetails } from "../models/ConsumerLoanDetails";
import {ProductType} from "../../../common/models/ProductType";
import {calculateLTV, calculateMortgageLoan} from "../helpers/calculators";
import { PropertiesSummary } from '../models/PropertiesSummary';
import NumberFormatter from "../../../common/components/NumberFormatter";
import { isLtvExceeded } from './ApplicationValidator';

interface PropertyDetailsInformationsProps {
    propertiesSummary: PropertiesSummary | null;
    loanDetails: ConsumerLoanDetails;
    onClick?: () => void;
    redemptions: Redemption[];
    numberOfLoans: number;
}

const PropertyInformation: FunctionComponent<PropertyDetailsInformationsProps> = (props) => {
    if (props.propertiesSummary == null || props.propertiesSummary.count === 0) {
        return null;
    }
    
    const sourceLTVAmount = calculateMortgageLoan(props.redemptions);
    const targetLTVAmount = props.loanDetails.productType === ProductType.Mortgage ? calculateMortgageLoan(props.redemptions, true) + props.loanDetails.loanAmount : null;

    return (
        <div className="property-information">
            <div className="property-title" onClick={props.onClick}><FontAwesomeIcon icon={faBuilding}/> {renderTitle(props.propertiesSummary.count)}</div>
            {renderDetails(props.propertiesSummary, sourceLTVAmount, targetLTVAmount)}            
        </div>
    );
};

const renderTitle= (count: number) => count > 1 ? <Translate id="PROPERTY.TITLE_PLURAL" data={{count}} /> : <Translate id="PROPERTY.TITLE" />;

const renderDetails = (summary: PropertiesSummary, sourceLTVAmount: number, targetLTVAmount: number | null) =>
    summary.count === summary.countWithValue ? renderDetailsForKnownValue(summary, sourceLTVAmount, targetLTVAmount) : renderDetailsForEsitmatedValue(summary, sourceLTVAmount, targetLTVAmount);

function renderDetailsForKnownValue(summary: PropertiesSummary, sourceLTVAmount: number, targetLTVAmount: number | null) {
    return (
        <div className="details">
            <div className="value">
                {renderPrice(summary.totalValue)}
                <div className="description"><Translate id="PROPERTY.VALUE" /></div>
            </div>
            {renderLTV("PROPERTY.LTV", summary, sourceLTVAmount, targetLTVAmount, summary.totalValue)}
            <div className="value">
                {renderPrice(summary.totalEstimatedValue)}<span> ({renderPercentage(calculateLTV(sourceLTVAmount, summary.totalCommonDebt, summary.totalEstimatedValue))})</span>
                <div className="description"><Translate id="PROPERTY.ESTIMATED" /></div>
            </div>
        </div>
    );

}

function renderDetailsForEsitmatedValue(summary: PropertiesSummary, sourceLTVAmount: number, targetLTVAmount: number | null) {
    const value = summary.countWithValue === 0
        ? '-'
        : <>{renderPrice(summary.totalValue)} ({summary.countWithValue}/{summary.count})</>

    return (
        <div className="details">
            <div className="value">
                <span className='missing-value'>{value}</span>
                <div className="description"><Translate id="PROPERTY.VALUE" /></div>
            </div>
            <div className="value">
                {renderPrice(summary.totalEstimatedValue)}
                <div className="description"><Translate id="PROPERTY.ESTIMATED_VALUE" /></div>
            </div>
            {renderLTV("PROPERTY.ESTIMATED_LTV", summary, sourceLTVAmount, targetLTVAmount, summary.totalLtvEstimatedValue)}
        </div>
    );
}

function renderLTV(key: string, summary: PropertiesSummary, sourceLTVAmount: number, targetLTVAmount: number | null, value: number | null) {
    if (value === null || value === 0) {
        return null;
    }
    
    const sourceLTV = calculateLTV(sourceLTVAmount, summary.totalCommonDebt, value);
    
    return (
        <div className="value">
            <span>{renderPercentage(sourceLTV)}
            {renderTargetLTV(summary, targetLTVAmount, value)}</span>
            <div className="description"><Translate id={key} /></div>
        </div>
    );
} 

function renderTargetLTV(summary: PropertiesSummary, targetLTVAmount: number | null, value: number | null) {
    if(targetLTVAmount === null || targetLTVAmount === 0) {
        return null;
    }
    
    const targetLTV = calculateLTV(targetLTVAmount, summary.totalCommonDebt, value);

    return (
        <>
            <FontAwesomeIcon style={{margin: '0 4px'}} icon={faLongArrowAltRight}/>
            <span style={{color: isLtvExceeded(targetLTV) ? '#F05B59' : '#42D253'}}>
                {renderPercentage(targetLTV)}
            </span>
        </>
    );
    
}

function renderPercentage(value:  number) {
    if (!isFinite(value) || isNaN(value)) {
        return "-";
    }

    return (value * 100).toFixed(1) + '%';
}

function renderPrice(value: number | null | undefined) {
    if (value === undefined || value === null || value === 0) {
        value = 0;
    }

    return (<span className="vamiddle"><NumberFormatter value={value}/></span>);
}

export default PropertyInformation;
