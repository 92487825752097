import React, {FunctionComponent, useMemo} from "react";
import {Translate} from "react-localize-redux";
import SubstatusesInputField from "../../input-fields/SubstatusesInputField";

interface AddSubstatusProps {
    current?: number[];
    toAdd: number[];
}
const AddSubstatus: FunctionComponent<AddSubstatusProps> = ({
    current,
    toAdd
}) => {
    const diff = useMemo(() => {
        return toAdd.filter(id => !current?.includes(id))
    }, [toAdd, current]);
    
    if (!diff.length) {
        return null;
    }
    
    return (
        <div className="add-substatus-template-post-action">
            <div className="description"><Translate id="ADD_SUBSTATUSES"/></div>
            <SubstatusesInputField substatusIds={diff}/>
        </div>
    )
}
export default AddSubstatus;