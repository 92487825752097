import React, {FunctionComponent, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import TextInputField from "../../../common/components/input-fields/TextInputField";
import {Translate} from "react-localize-redux";
import {MarketingList} from "../../model/MarketingList";

interface CreateMarketingListModalProps {
    show?: boolean;
    list?: MarketingList;
    onSubmit?: (list: MarketingList) => void;
    onCancel?: () => void;
}

const CreateMarketingListModal: FunctionComponent<CreateMarketingListModalProps> = (props) => {
    const [name, setName] = useState<string | undefined>(props.list?.name);
    const isCopy = !!props.list?.name;
    return (
        <Modal 
            show={props.show} 
            centered={true} 
            className="create-marketing-list" 
        >
            <Modal.Header>
                <Modal.Title>
                    <Translate 
                        id={`MARKETING_LISTS_VIEW.${isCopy ? 'COPY' : 'CREATE'}_MARKETING_LIST`}
                        data={{name: props.list?.name}}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <TextInputField
                        descriptionKey="NAME"
                        editMode={true}
                        name="listName"
                        onValueChanged={setName}
                        required={'ContentIsRequired'}
                        value={name}
                    />
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="primary" 
                    className="submit-button" 
                    disabled={!name || name === props.list?.name}
                    onClick={() => props.onSubmit && props.onSubmit({...(props.list || {}), name, id: undefined})}
                >
                    <Translate id={isCopy ? 'COPY' : 'CREATE'} />
                </Button>
                <Button variant="secondary" onClick={props.onCancel}>
                    <Translate id="CANCEL" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default CreateMarketingListModal;