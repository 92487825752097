import React, { FunctionComponent, useState } from 'react';
import { Substatus } from '../models/Substatus';
import SubstatusesActionsCreator from '../actions/SubstatusesActionsCreator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import { loanStatusToColor } from '../../../common/helpers/loanStatusToColor';
import { LoanStatus } from '../../../common/models/LoanStatus';
import { Storage } from '../../../common/helpers/Storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { UserProps } from '../../../common/interfaces/UserProps';

interface SubstatusesProps {
    substatuses: Substatus[] | undefined;
    isLoading: boolean;
}

interface SubstatusesFilterProps {
    selectedSubstatusIds: number[];
    loanStatus?: LoanStatus;
    onSubstatusesChanged: (substatusIds: number[]) => void;
}

const SubstatusesFilter: FunctionComponent<SubstatusesProps & UserProps & SubstatusesFilterProps & typeof SubstatusesActionsCreator> = (props) => {
    const [showAllSubstatuses, setShowAllSubstatuses] = useState(false);
    const storedPreferredSubstatuses: Array<{ substatusId: number, lastUse: Date }> = Storage.getItem('preferred-substatuses') || [];
    const [preferredSubstatuses] = useState(storedPreferredSubstatuses);
    
    if (props.substatuses === undefined) {

        if (!props.isLoading) {
            props.loadSubstatuses();
        }

        return renderLoading();
    }

    const ownSubstatus = props.substatuses.find((s) => props.userData.user && props.userData.user.id === s.userId);
    const onClick = (id: number) => {
        const newPreferredSubstatuses = [
            { substatusId: id, lastUse: new Date() },
            ...storedPreferredSubstatuses.filter((s) => s.substatusId !== id)
        ];
        Storage.setItem('preferred-substatuses', newPreferredSubstatuses);

        props.onSubstatusesChanged(
            props.selectedSubstatusIds.indexOf(id) >= 0
                ? props.selectedSubstatusIds.filter((s) => s !== id)
                : props.selectedSubstatusIds.concat([id])
        );
    };

    const toggleShowAllSubstatuses = () => setShowAllSubstatuses(!showAllSubstatuses);

    const substatuses = filterSubstatuses(props.substatuses, props.loanStatus);
    const prioritySortedSubstatuses = sortByUsage(substatuses, ownSubstatus, preferredSubstatuses);
    const subStatusesToDisplay = filterShowAllSubstatuses(prioritySortedSubstatuses, showAllSubstatuses);

    return (
        <div>
            {subStatusesToDisplay.map((s) => renderSubstatus(s, props.selectedSubstatusIds.indexOf(s.id) >= 0, () => onClick(s.id)))}
            {renderShowMoreLessButton(showAllSubstatuses, toggleShowAllSubstatuses, prioritySortedSubstatuses.length > 15)}
        </div>
    );
};

function renderLoading() {
    return <Translate id="LOADING" />;
}

function filterSubstatuses(substatuses: Substatus[], loanStatus?: LoanStatus) {
    if (loanStatus === undefined) {
        return substatuses;
    }

    return substatuses.filter((s) => s.loanStatuses.some(sls => sls.loanStatus === loanStatus));
}

function renderShowMoreLessButton(showAllSubstatuses: boolean, toggleShowAllSubstatuses: () => void, shouldRender: boolean) {
    return shouldRender ? (
        <span className="status status-gray cursor-pointer" onClick={toggleShowAllSubstatuses}>
            <Translate id={showAllSubstatuses ? 'SHOW_LESS' : 'SHOW_MORE'} />
            <FontAwesomeIcon style={{ marginLeft: '3px' }} icon={showAllSubstatuses ? faChevronCircleLeft : faChevronCircleRight} />
        </span>
    ) : null;
}

function filterShowAllSubstatuses(substatuses: Substatus[], showAll: boolean) {
    return substatuses.filter((_, i) => i < 15 || showAll);
}

function sortByUsage(substatuses: Substatus[], ownSubstatus: Substatus | undefined, preferredSubstatuses: Array<{ substatusId: number, lastUse: Date }>) {
    const compareFn = (s1: Substatus, s2: Substatus) => {
        if (ownSubstatus !== undefined) {
            if (s1.id === ownSubstatus.id) { return -1; }
            if (s2.id === ownSubstatus.id) { return 1; }
        }

        const ps1 = preferredSubstatuses.find((x) => x.substatusId === s1.id);
        const ps2 = preferredSubstatuses.find((x) => x.substatusId === s2.id);

        if (ps1 !== undefined && ps2 !== undefined) {
            return new Date(ps2.lastUse).getTime() - new Date(ps1.lastUse).getTime();
        }

        if (ps1 !== undefined) { return -1; }
        if (ps2 !== undefined) { return 1; }

        return 0;
    };

    return substatuses.sort(compareFn);
}

function renderSubstatus(substatus: Substatus, selected: boolean, onClick: () => void) {
    const cssClasses = classNames('status', getCssClass(substatus), 'cursor-pointer', { 'not-selected': !selected });
    return <span key={substatus.id} className={cssClasses} onClick={onClick}>{substatus.name}</span>;
}

function getCssClass(substatus: Substatus) {
    if (substatus.loanStatuses.length !== 1) {
        return 'status-gray';
    }

    return `status-${loanStatusToColor(substatus.loanStatuses[0].loanStatus)}`;
}

const mapStateToProps = (state: any) => ({
    ...state.userActionsReducer,
    ...state.substatusesReducer
});

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators(SubstatusesActionsCreator, dispatch);

export default connect<SubstatusesProps & UserProps, typeof SubstatusesActionsCreator, SubstatusesFilterProps, any>(mapStateToProps, mapActionCreatorsToProps)(SubstatusesFilter);
