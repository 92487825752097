import React, {CSSProperties, FunctionComponent, useCallback, useEffect} from 'react';
import "./substatuses-input-field.css";
import { MultiSelect } from '../MultiSelect';
import { Substatus } from '../../../applications/applicants/models/Substatus';
import { loanStatusToColor } from '../../helpers/loanStatusToColor';
import { SubstatusesProps } from '../../interfaces/SubstatusesProps';
import SubstatusesActionsCreator from '../../../applications/applicants/actions/SubstatusesActionsCreator';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { LoanStatus } from '../../models/LoanStatus';
import {isValueSet} from "../../helpers/isValueSet";

interface SubstatusesInputFieldProps {
    className?: string;
    substatusIds: number[];
    onSubstatusesChanged?: (ids: number[]) => void;
    placeholder?: string;
    placeholderKey?: string;
    description?: string;
    descriptionKey?: string;
    editMode?: boolean;
    inlineEditing?: boolean;
    onlyImportant?: LoanStatus;
    style?: CSSProperties;
}

interface LoadSubstatusesActions {
    loadSubstatuses: () => void;
}

const SubstatusesInputField: FunctionComponent<SubstatusesInputFieldProps & SubstatusesProps & LoadSubstatusesActions & LocalizeContextProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { if (props.substatuses === undefined) { props.loadSubstatuses(); } }, []);
    const setSubstatuses = (newSubstatuses: Substatus[]) => props.onSubstatusesChanged && props.onSubstatusesChanged(newSubstatuses.map((x) => x.id));
    const substatuses = props.substatuses === undefined ? [] : props.substatuses;
    const selectedSubstatuses = (props.substatuses === undefined ? [] : props.substatuses.filter((x) => (props.substatusIds.indexOf(x.id) > -1)));
    const placeholder = props.placeholder || (props.placeholderKey && props.translate(props.placeholderKey).toString());

    const styleSubstatus = useCallback((s: Substatus): string => {
        const classNames = ['status'];
        const isImportant = s.loanStatuses.some(sls => sls.loanStatus === props.onlyImportant && sls.important);
        if (isImportant) {
            classNames.push('important');
            classNames.push(loanStatusToColor(props.onlyImportant, 'status-'));
        } else {
            if (s.loanStatuses.length !== 1) {
                classNames.push('status-gray');
            } else {
                classNames.push(loanStatusToColor(s.loanStatuses[0].loanStatus, 'status-'));
            }
        }
        return classNames.join(' ')
    }, [props.onlyImportant]);
    
    const onlyImportantClass = isValueSet(props.onlyImportant) ? 'only-important' : ''
    
    if (!props.editMode) {
        return (
            <div className={`substatuses-input-field readonly ${onlyImportantClass}`}>
                {
                    selectedSubstatuses.map((substatus) => (
                        <span key={substatus.id} className={styleSubstatus(substatus)}>
                            {substatus.name}
                        </span>
                    ))
                }
            </div>
        )
    }

    return (
        <MultiSelect
            className={`substatuses-input-field ${props.className} ${onlyImportantClass}`}
            description={props.description}
            descriptionKey={props.descriptionKey}
            selectedItems={selectedSubstatuses}
            items={substatuses}
            onSelectionChanged={setSubstatuses}
            inlineEditing={props.inlineEditing}
            style={props.style}
            placeHolder={placeholder}
            getItemCustomCssClass={styleSubstatus}
        />
    );
};

const mapStateToProps = (state: any) => ({
    ...state.substatusesReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({ ...SubstatusesActionsCreator }, dispatch);

export default connect<SubstatusesProps, LoadSubstatusesActions, SubstatusesInputFieldProps, any>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(SubstatusesInputField));
