import React, { FunctionComponent, ReactNode } from 'react';
import { PropertyInformation } from '../../models/PropertyInformation';
import './property-information-modal.css';
import { getPropertyTypesTranslateKeys } from '../../helpers/getPropertyTypesTranslateKeys';
import { Translate } from 'react-localize-redux';
import ExternalSystemLink from '../../../../common/components/ExternalSystemLink';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../../../../common/components/Tooltip';
import { PropertyType } from '../../models/PropertyType';
import NumberInputField, { NumberInputFieldKind } from '../../../../common/components/input-fields/NumberInputField';
import TextInputField from '../../../../common/components/input-fields/TextInputField';
import EditIcons from '../EditIcons';
import { getRealEstateInformationPageUrl } from '../../api/getRealEstateInformationPageUrl';
import PropertiesInfomationModalOwnershipsTable from './PropertiesInfomationModalOwnershipsTable';

interface PropertiesInfomationModalPropertyCardProps {
    property: PropertyInformation;
    editedProperty: PropertyInformation | null;
    setEditedProperty: (p: PropertyInformation | null) => void;
    saveProperty: (p: PropertyInformation) => Promise<string[]>;
}


const PropertiesInfomationModalPropertyCard: FunctionComponent<PropertiesInfomationModalPropertyCardProps> = (props) => {
    const property = props.editedProperty?.id === props.property.id ? props.editedProperty : props.property;
    const enterEditMode = () => props.setEditedProperty(property);
    const onValueChanged = (value: number | string | null | undefined, name: string) => props.setEditedProperty({...property, [name]: value });

    return (
        <div className="property column-card" style={{ alignItems: "center" }} key={renderCadastreIdentifier(property)}>
            <div>
                <div><b>{formatAddress(property)}</b>{renderPropertyInformationExternalLink(property)}
                    <div className='options'>{renderIcons(props)}</div></div>
                <div className='property-type'>
                    {renderPropertyType(property.propertyType)}
                </div>
                {renderHousingCooperativeIdentification(property.cooperationOrganizationNumber, property.shareNumber)}
                {renderCadastreIdentifier(property)}
                <div className='property-data'>
                    <div style={{ width: '50%' }}>
                        <div onClick={enterEditMode}>
                            <NumberInputField
                                descriptionKey="PROPERTIES_MODAL.VALUE"
                                editMode={isEditMode(props)}
                                name="value"
                                nullable={true}
                                kind={NumberInputFieldKind.Money}
                                value={property.value}
                                onValueChanged={onValueChanged}
                            />
                        </div>
                        <div onClick={enterEditMode}>
                            <TextInputField
                                descriptionKey="INSURANCE"
                                editMode={isEditMode(props)}
                                name="insurance"
                                value={property.insurance || getDefaultInsunrace(props)}
                                onValueChanged={onValueChanged}
                            />
                        </div>
                        {renderCommonDebt(property)}
                    </div>
                    <div style={{ width: '50%' }}>
                        <Tooltip content={<Translate id="PROPERTIES_MODAL.ACCURACY_LEVEL" data={{accuracyLevel: formatAccuracyLevel(property)}} />}>
                            <div style={{'display':'inline-block'}}>
                            <NumberInputField
                                descriptionKey="PROPERTIES_MODAL.ESTIMATED_VALUE"
                                editMode={false}
                                name="estimatedValue"
                                kind={NumberInputFieldKind.Money}
                                value={property.estimatedValue}
                                afterContent={renderAccuracyBadge(property)}
                            />
                            </div>
                        </Tooltip>
                        <NumberInputField
                            descriptionKey="PROPERTIES_MODAL.AREA_MARKET_SCORE"
                            editMode={false}
                            name="areaMarketScore"
                            value={property.areaMarketScore}
                        />
                        <NumberInputField
                            descriptionKey="PROPERTIES_MODAL.MUNICIPALITY_SCORE"
                            editMode={false}
                            name="municipalityScore"
                            value={property.municipalityScore}
                        />
                    </div>
                </div>
                <b><Translate id="PROPERTIES_MODAL.OWNERS" /></b>
                <PropertiesInfomationModalOwnershipsTable propertyOwnerships={property.propertyOwnerships}/>
            </div>
        </div>
    )
};


const renderPropertyType = (propertyType: PropertyType | null): ReactNode => propertyType === null
    ? <Translate id="PROPERTY_TYPES.UNKNOWN" />
    : <Translate id={getPropertyTypesTranslateKeys(propertyType)} />;

const formatAddress = (property: PropertyInformation): ReactNode => {
    const formatStreetLetter = property.streetLetter === null ? '' : ` ${property.streetLetter}`;

    if (property.streetName === null || property.streetNumber === null || property.postalCode === null || property.municipality === null) {
        return <Translate id="PROPERTIES_MODAL.NO_ADDRESS" />
    }

    return `${property.streetName || ''} ${property.streetNumber || ''}${formatStreetLetter}, ${property.postalCode} ${property.municipality}`;
};

const renderHousingCooperativeIdentification = (cooperationOrganizationNumber: number | null, shareNumber: number | null): ReactNode | null => {
    return shareNumber !== null && cooperationOrganizationNumber !== null
        ? (<div>{cooperationOrganizationNumber} / {shareNumber}</div>)
        : null;
};

const renderCadastreIdentifier = (property: PropertyInformation): string => {
    if (property.municipalityNumber === null || property.cadastralUnitNumber === null || property.propertyUnitNumber === null || property.leaseholdUnitNumber === null || property.condominiumUnitNumber === null) {
        return "Missing cadastre data"
    }

    return `${property.municipalityNumber}-${property.cadastralUnitNumber}/${property.propertyUnitNumber}/${property.leaseholdUnitNumber}/${property.condominiumUnitNumber}`;
};

const renderPropertyInformationExternalLink = (property: PropertyInformation): ReactNode => {
    if (property.cadastralUnitNumber === null || property.condominiumUnitNumber === null || property.municipalityNumber === null || property.propertyUnitNumber === null || property.leaseholdUnitNumber === null) {
        return null;
    }

    const getUrl = () => getRealEstateInformationPageUrl(property);

    return (
        <ExternalSystemLink as='a' style={{ marginLeft: '5px', cursor: 'pointer' }} getUrl={getUrl}>
            <Tooltip content={<Translate id="PROPERTIES_MODAL.EIENDOMSVERDI_WEBSITE" />}>
                <FontAwesomeIcon icon={faUpRightFromSquare} />
            </Tooltip>
        </ExternalSystemLink>
    );
}

const getDefaultInsunrace = (props: PropertiesInfomationModalPropertyCardProps) => isEditMode(props) ? '' : '-';

const isEditMode = (props: PropertiesInfomationModalPropertyCardProps) => props.editedProperty !== null && props.editedProperty.id === props.property.id;

const renderIcons = (props: PropertiesInfomationModalPropertyCardProps) => {
    const exitedEditCallback = () => props.setEditedProperty(null);

    return props.editedProperty !== null && isEditMode(props) ?
        <EditIcons<PropertyInformation>
            exitedEditCallBack={exitedEditCallback}
            canEnterEdit={true}
            editMode={true}
            data={props.editedProperty}
            saveCallBack={props.saveProperty}
            enteredEditCallBack={()=>{}}
        />
        : null;
};

function formatAccuracyLevel(property: PropertyInformation): ReactNode {
    return property.accuracyLevel ? property.accuracyLevel : <Translate id="UNKNOWN" />;
}

function renderAccuracyBadge(property: PropertyInformation): ReactNode {
    return property.accuracyLevel ? <div className={`accuracy-badge accuracy-${property.accuracyLevel}`}>{property.accuracyLevel}</div> : null;
}

function renderCommonDebt(property: PropertyInformation): ReactNode {
    if(property.commonDebt === null || property.commonDebt === 0) {
        return null;
    }

    return <NumberInputField
        descriptionKey="PROPERTIES_MODAL.COMMON_DEBT"
        editMode={false}
        name="commonDebt"
        kind={NumberInputFieldKind.Money}
        value={property.commonDebt}
    />;
}

export default PropertiesInfomationModalPropertyCard;
