import { faBars, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {CSSProperties, FunctionComponent} from 'react';
import { Dropdown } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { UserProps } from '../../../common/interfaces/UserProps';
import { connect } from 'react-redux';
import { Role } from '../../../common/models/Role';
import ActionIcon from '../../../common/components/ActionIcon';

interface ActionMenuProps {
    newApplication(): void;
    exportCustomers?(): void;
    exportApplications?(): void;
}

const ActionMenu: FunctionComponent<ActionMenuProps & UserProps> = (props: ActionMenuProps & UserProps) => {
    const iconStyle: CSSProperties = {
        minWidth: '32px',
        minHeight: '32px',
        color: '#707070',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };

    if (props.userData && props.userData.user && props.userData.user.role >= Role.Admin) {
        return (
            <Dropdown drop="down" align="end" className="action-menu">
                <Dropdown.Toggle id="applicants-menu" className="btn-white-bg" style={iconStyle}>
                    <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <div className="dropdown-arrow-down" />
                <Dropdown.Menu>
                    <Dropdown.Item key="new-application" onClick={props.newApplication}>
                        <Translate id="NEW_APPLICATION" />
                    </Dropdown.Item>
                    {props.exportApplications && props.exportCustomers ? 
                        <>
                            <Dropdown.Divider key="applicant-actions-divider-1" />
                            <Dropdown.Item key="export-customers" onClick={props.exportCustomers}>
                                <Translate id="EXPORT_CUSTOMERS" />
                            </Dropdown.Item>
                            <Dropdown.Item key="export-applications" onClick={props.exportApplications}>
                                <Translate id="EXPORT_APPLICATIONS" />
                            </Dropdown.Item>
                        </> : null
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    return (
        <div style={iconStyle} className="action-menu">
            <ActionIcon icon={faPlusSquare} tooltipKey="NEW_APPLICATION" action={props.newApplication} />
        </div>
    );
};

const mapStateToProps = (state: any) => ({ ...state.userActionsReducer });

export default connect<UserProps, {}, ActionMenuProps, any>(mapStateToProps)(ActionMenu);
