import React, {FunctionComponent, MouseEvent, MouseEventHandler, useEffect, useMemo, useState} from "react";
import {Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBell,
    faCircle,
    faMobileAlt,
    faPhoneSlash,
    faPlusSquare,
    faUserMinus,
    faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import RefreshButton from "../../../../common/components/RefreshButton";
import TablePagination from "../../../../common/components/table/TablePagination";
import TableHeader, {TableHeaderCellModel} from "../../../../common/components/table/TableHeader";
import {LoanStatus} from "../../../../common/models/LoanStatus";
import {getLoanStatusTranslateKey} from "../../../../common/helpers/loanStatusFunctions";
import TableRows from "../../../../common/components/table/TableRows";
import {Translate} from "react-localize-redux";
import {loanStatusToColor} from "../../../../common/helpers/loanStatusToColor";
import {MarketingListCustomer} from "../../../model/MarketingListCustomer";
import {NavLink} from "react-router-dom";
import CopyToClipboardButton from "../../../../common/components/CopyToClipboardButton";
import SendSmsModal from "../../../../common/components/SendSmsModal";
import SendEmailModal from "../../../../common/components/SendEmailModal";
import {MarketingListCustomerStatus} from "../../../model/MarketingListCustomerStatus";
import {beautifyName} from "../../../../common/helpers/beautifyName";
import {bindActionCreators, Dispatch} from "redux";
import CustomerAdvisorsTeamsActionsCreator, {
    CustomerAdvisorsTeamsActions
} from "../../../../applications/applicants/actions/CustomerAdvisorsTeamsActionsCreator";
import {connect} from "react-redux";
import {CustomerAdvisorsTeamsProps} from "../../../../common/interfaces/CustomerAdvisorsTeamsProps";
import {CustomerAdvisor} from "../../../../applications/applicant/models/CustomerAdvisor";
import Tooltip from "../../../../common/components/Tooltip";
import ApplicantTooltip from "../../../../applications/applicants/components/ApplicantTooltip";
import DateTimeFormatter from "../../../../common/components/DateTimeFormatter";
import {Ordering} from "../../../../common/models/TableState";
import {formatDateWithTime} from "../../../../common/helpers/dateFormatter";
import NumberFormatter from "../../../../common/components/NumberFormatter";
import {SubstatusesProps} from "../../../../common/interfaces/SubstatusesProps";
import SubstatusesActionsCreator from "../../../../applications/applicants/actions/SubstatusesActionsCreator";
import {Substatus, SubstatusLoanStatus} from "../../../../applications/applicants/models/Substatus";
import ProductIcon from "../../../../common/components/ProductIcon";
import FollowUpInlineInputField from "../../../../common/components/input-fields/FollowUpInlineInputField";
import AddCommentAction from "../../../../common/components/table-cells/actions/AddCommentAction";

interface CustomersTableProps extends CustomerAdvisorsTeamsProps, CustomerAdvisorsTeamsActions, SubstatusesProps {
    customers?: MarketingListCustomer[];
    marketingListId?: number;
    count?: number;
    editable?: boolean;
    pageNumber: number;
    pageSize: number;
    orderings?: Ordering<MarketingListCustomer>[];
    isDataLoading?: boolean; // conflict name with substatusesReducer
    isOutdated?: boolean;
    isPaidFilter?: boolean;
    onPageChange?: (page: number) => void;
    onPageSizeChange?: (size: number) => void;
    onOrderingsChange?: (orderings: Ordering<MarketingListCustomer>[]) => void;
    onRefreshDataClick?: () => void;
    onCustomerFollowUpDateChange?: (customer: MarketingListCustomer, followUpDate?: string | null) => void;
    onCustomerStatusChange?: (customer: MarketingListCustomer, status: MarketingListCustomerStatus) => void;
    onCustomerCommentSave?: (customer: MarketingListCustomer, comment: string) => void;
}

const CustomersTable: FunctionComponent<CustomersTableProps> = (props) => {
    const [sendSmsModalToCustomer, setSendSmsModalToCustomer] = useState<MarketingListCustomer | undefined>();
    const [sendEmailModalToCustomer, setSendEmailModalToCustomer] = useState<MarketingListCustomer | undefined>();
    const [editCustomerFollowUp, setEditCustomerFollowUp] = useState<MarketingListCustomer | undefined>();
    const columns = useMemo(() => ([
        { index: 0, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.APPLICANT' },
        { index: 1, translateKey: 'EMAIL' },
        { index: 2, translateKey: 'MOBILE' },
        { index: 3, translateKey: 'PRODUCT', name: 'productType' },
        { index: 4, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.STATUS', name: 'loanStatus' },
        { index: 5, translateKey: 'MARKETING_LISTS_VIEW.TABLE_HEADERS.APPLIED_AMOUNT', name: 'appliedAmount' },
        { index: 7, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.DATE', name: 'submittedDate' },
        { index: 8, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.FOLLOW_UP', name: 'followUpDate' },
        { index: 9, translateKey: 'CUSTOMER_ADVISOR', name: 'customerAdvisorId' }
    ] as TableHeaderCellModel<MarketingListCustomer>[])
        .concat(props.isPaidFilter ? [{ index: 6, translateKey: 'MARKETING_LISTS_VIEW.TABLE_HEADERS.PAID_AMOUNT', name: 'paidAmount' }] : [])
        .concat(props.editable ? [{ index: 10, translateKey: 'MARKETING_LISTS_VIEW.TABLE_HEADERS.ACTIONS' }] : [])
        , [props.isPaidFilter, props.editable]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { if (props.customerAdvisorsTeams === undefined) { props.loadCustomerAdvisorsTeams(); } }, []);

    const showSendSmsModal = (e: MouseEvent, customer: MarketingListCustomer) => {
        e.preventDefault();
        e.stopPropagation();
        setSendSmsModalToCustomer(customer);
    }

    const showSendEmailModal = (e: MouseEvent, customer: MarketingListCustomer) => {
        e.preventDefault();
        e.stopPropagation();
        setSendEmailModalToCustomer(customer);
    }

    const onFollowUpChange = (followUp?: string | null) => {
        if (editCustomerFollowUp && props.onCustomerFollowUpDateChange) {
            props.onCustomerFollowUpDateChange(editCustomerFollowUp, followUp);
        }
    }

    return (
        <>
            <RefreshButton
                style={{ position: 'absolute', right: '-48px' }}
                isLoading={!!props.isDataLoading}
                onClick={props.onRefreshDataClick}
            >
                {!props.isDataLoading && props.isOutdated &&
                    <div className="filter-indicator" style={{ fontSize: '6px', top: '2px', right: '6px' }}><FontAwesomeIcon icon={faCircle} /></div>
                }
            </RefreshButton>
            <Table
                className="customers-table"
                hover={true}
                borderless={true}
                style={{ marginBottom: '24px' }}
            >
                <TableHeader cells={columns} orderings={props.orderings} onOrderingsChange={props.onOrderingsChange} />
                <TableRows<MarketingListCustomer>
                    rows={props.customers || []}
                    renderRow={(customer, index) => (
                        <tr
                            key={`${index}-${customer.personId}`}
                            className={customer.marketingStatus === MarketingListCustomerStatus.NotInterested ? 'not-interested' : ''}
                        >
                            <td>{renderCustomerCell(customer)}</td>
                            <td>{renderEmailCell(customer.email, e => showSendEmailModal(e, customer))}</td>
                            <td>{renderMobileNumberCell(customer.mobileNumber, e => showSendSmsModal(e, customer))}</td>
                            <td className="product-cell"><ProductIcon product={customer.productType}/></td>
                            <td className="status-cell">{renderLoanStatusCell(customer.loanStatus, customer.substatusIds, props.substatuses)}</td>
                            <td>{renderNumberCell(customer.appliedAmount)}</td>
                            {props.isPaidFilter &&
                                <td>{renderNumberCell(customer.paidAmount)}</td>
                            }
                            <td><DateTimeFormatter date={customer.submittedDate} /></td>
                            <td className="follow-up-cell">
                                <FollowUpInlineInputField
                                    followUpDate={editCustomerFollowUp?.personId === customer.personId ? editCustomerFollowUp.followUpDate : customer.followUpDate}
                                    editMode={editCustomerFollowUp?.personId === customer.personId}
                                    onChange={date => setEditCustomerFollowUp({ ...editCustomerFollowUp!, followUpDate: date?.toISOString()})}
                                    onFocus={props.editable && !editCustomerFollowUp ? () => setEditCustomerFollowUp({ ...customer }) : undefined}
                                    onBlur={() => {
                                        if (customer.followUpDate !== editCustomerFollowUp?.followUpDate) {
                                            onFollowUpChange(editCustomerFollowUp?.followUpDate);
                                        }
                                        setEditCustomerFollowUp(undefined);
                                    }}
                                />
                            </td>
                            <td>{renderCustomerAdvisorCell(props.customerAdvisors?.find(a => a.id === customer.customerAdvisorId))}</td>
                            {props.editable &&
                                <td className="actions-cell">{renderActionsCell(props, customer)}</td>
                            }
                        </tr>
                    )}
                />
            </Table>
            <TablePagination
                isLoading={props.isDataLoading}
                pageNumber={props.pageNumber}
                pageSize={props.pageSize}
                onPagePicked={props.onPageChange}
                onPageSizePicked={props.onPageSizeChange}
                count={props.count || 0}
                pickableSizes={[20, 50, 100, 250, 500]}
            />
            {!!sendSmsModalToCustomer && sendSmsModalToCustomer.mobileNumber !== null &&
                <SendSmsModal
                    show={true}
                    productType={sendSmsModalToCustomer?.productType}
                    applicant={sendSmsModalToCustomer}
                    applicationId={sendSmsModalToCustomer?.applicationId}
                    marketingListId={props.marketingListId}
                    publicId={sendSmsModalToCustomer?.publicId}
                    selectedMobileNumber={sendSmsModalToCustomer?.mobileNumber}
                    onClose={(smsSent) => {
                        sendSmsModalToCustomer!.smsSent = !!smsSent;
                        setSendSmsModalToCustomer(undefined);
                    }}
                />
            }
            {!!sendEmailModalToCustomer && sendEmailModalToCustomer.email !== null &&
                <SendEmailModal
                    show={true}
                    productType={sendEmailModalToCustomer?.productType}
                    applicant={sendEmailModalToCustomer}
                    applicationId={sendEmailModalToCustomer?.applicationId}
                    publicId={sendEmailModalToCustomer?.publicId}
                    selectedEmail={sendEmailModalToCustomer?.email}
                    onClose={() => setSendEmailModalToCustomer(undefined)}
                />
            }
        </>
    )
}

const renderCustomerCell = (customer: MarketingListCustomer) => {
    const path = customer.applicationId
        ? `/applicant/${customer.personId}/application/${customer.applicationId}`
        : `/applicant/${customer.personId}`;

    let displayName: JSX.Element | string = <Translate id="NO_DATA" />;
    if (customer.firstName || customer.lastName) {
        displayName = beautifyName(`${customer.firstName || ''} ${customer.lastName || ''}`, false)
    }

    const iconStyle = { fontSize: '10px', marginRight: '4px', minWidth: '12px' };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {customer.marketingStatus === MarketingListCustomerStatus.Unanswered &&
                <Tooltip content={<Translate id="MARKETING_LISTS_VIEW.CUSTOMER_NOT_ANSWER" />}>
                    <FontAwesomeIcon
                        icon={faPhoneSlash}
                        style={iconStyle}
                    />
                </Tooltip>
            }
            {customer.smsSent &&
                <Tooltip content={<Translate id="MARKETING_LISTS_VIEW.CUSTOMER_SENT_SMS" />}>
                    <FontAwesomeIcon
                        icon={faMobileAlt}
                        style={iconStyle}
                    />
                </Tooltip>
            }
            {customer.followUpDate &&
                <Tooltip content={<Translate id="MARKETING_LISTS_VIEW.CUSTOMER_PLANNED_ON" data={{ plannedOn: formatDateWithTime(customer.followUpDate) }} />}>
                    <FontAwesomeIcon
                        icon={faBell}
                        style={iconStyle}
                    />
                </Tooltip>
            }
            <ApplicantTooltip {...customer}>
                <NavLink to={path} target="_blank">
                    {displayName}
                </NavLink>
            </ApplicantTooltip>
        </div>
    );
}

const renderEmailCell = (email: string | null, onClick: MouseEventHandler) => email === null ? null : (
    <CopyToClipboardButton content={email}>
        <Tooltip content={email}>
            <a
                href={`mailto:${email}`}
                style={{
                    textTransform: 'lowercase',
                    maxWidth: '100px',
                    textOverflow: 'ellipsis',
                    display: 'table-cell',
                    overflow: 'hidden'
                }}
                onClick={onClick}
            >{email}</a>
        </Tooltip>
    </CopyToClipboardButton>
)

const renderMobileNumberCell = (mobileNumber: string | null, onClick: MouseEventHandler) => mobileNumber === null ? null : (
    <CopyToClipboardButton content={mobileNumber}>
        <a
            href={`tel:${mobileNumber}`}
            onClick={onClick}
        >{mobileNumber}</a>
    </CopyToClipboardButton>
)

const renderLoanStatusCell = (status: LoanStatus, substatusIds: number[], substatuses: Substatus[] | undefined) => {
    const statusClassName = `status${loanStatusToColor(status, ' status-')}`;
    const itemStyle: React.CSSProperties = { width: 'fit-content' };
    const substatus = substatuses !== undefined
        ? substatusIds.filter(id => substatuses.some(s => s.id === id)).map(id => {
            const substatus = substatuses.find(s => s.id === id)!;
            return <div className={getSubstatusClassNames(status, substatus.loanStatuses)} style={itemStyle}>{substatus.name}</div>;
        })
        : null;

    return (
        <div className="statuses" style={{ display: 'flex' }}>
            <div className={statusClassName} style={itemStyle}>
                <Translate id={getLoanStatusTranslateKey(status)} />
            </div>
            {substatus}
        </div>
    );
}

const getSubstatusClassNames = (status: LoanStatus, substatusLoanStatuses: SubstatusLoanStatus[]): string => (
    substatusLoanStatuses.some(s => s.loanStatus === status)
        ? `status${loanStatusToColor(status, ' status-')}`
        : 'status status-gray'
);

const renderNumberCell = (value: number | null | undefined) => (
    <div style={{ width: 'fit-content', textAlign: 'right', minWidth: '90px' }}>
        <NumberFormatter value={value} />
    </div>
);

const renderCustomerAdvisorCell = (customerAdvisor?: CustomerAdvisor) => {
    if (!customerAdvisor) {
        return <Translate id="NO_ADVISOR" />
    }

    return (
        <Tooltip content={customerAdvisor.displayName}>
            <span style={{
                maxWidth: '70px',
                textOverflow: 'ellipsis',
                display: 'table-cell',
                overflow: 'hidden'
            }}>
                {customerAdvisor.displayName}
            </span>
        </Tooltip>
    )
}

const renderActionsCell = (props: CustomersTableProps, customer: MarketingListCustomer) => (
    <div>
        <Tooltip content={<Translate id="NEW_APPLICATION" />}>
            <FontAwesomeIcon icon={faPlusSquare} onClick={() => window.open(`/applicant/${customer.personId}/application/${customer.applicationId}/copy`, '_blank')} />
        </Tooltip>
        {customer.marketingStatus === MarketingListCustomerStatus.Unhandled &&
            <Tooltip content={<Translate id="MARKETING_LISTS_VIEW.CUSTOMER_NOT_ANSWER" />}>
                <FontAwesomeIcon
                    icon={faPhoneSlash}
                    onClick={() => props.onCustomerStatusChange?.(customer, MarketingListCustomerStatus.Unanswered)}
                />
            </Tooltip>
        }
        {customer.marketingStatus !== MarketingListCustomerStatus.NotInterested &&
            <Tooltip content={<Translate id="MARKETING_LISTS_VIEW.CUSTOMER_NOT_INTERESTED" />}>
                <FontAwesomeIcon
                    icon={faUserMinus}
                    onClick={() => props.onCustomerStatusChange?.(customer, MarketingListCustomerStatus.NotInterested)}
                />
            </Tooltip>
        }
        {customer.marketingStatus === MarketingListCustomerStatus.NotInterested &&
            <Tooltip content={<Translate id="MARKETING_LISTS_VIEW.CUSTOMER_INTERESTED" />}>
                <FontAwesomeIcon
                    icon={faUserPlus}
                    onClick={() => props.onCustomerStatusChange?.(customer, MarketingListCustomerStatus.Unhandled)}
                />
            </Tooltip>
        }
        <AddCommentAction onSave={comment => props.onCustomerCommentSave?.(customer, comment)} />
    </div>
);


const mapStateToProps = (state: any) => ({
    ...state.customerAdvisorsTeamsReducer,
    ...state.substatusesReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...CustomerAdvisorsTeamsActionsCreator,
    ...SubstatusesActionsCreator
}, dispatch);

export default connect<CustomerAdvisorsTeamsProps & SubstatusesProps, CustomerAdvisorsTeamsActions & typeof SubstatusesActionsCreator>(mapStateToProps, mapActionCreatorsToProps)(CustomersTable);