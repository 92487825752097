import React, {FunctionComponent} from "react";
import {Translate} from "react-localize-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {LoanStatus} from "../../../models/LoanStatus";
import {loanStatusToColor} from "../../../helpers/loanStatusToColor";
import {getEnumTranslationKey} from "../../../helpers/getTranslationKey";

interface ChangeStatusProps {
    from?: LoanStatus;
    to: LoanStatus;
}
const ChangeStatus: FunctionComponent<ChangeStatusProps> = ({
    from,
    to
}) => {
    if (from === undefined || from === to) {
        return null;
    }

    return (
        <div className="change-status-template-post-action">
            <div className="description"><Translate id="CHANGE_STATUS"/></div>
            <div className="content">
                <span className={loanStatusToColor(from, 'status status-')}>
                    <Translate id={getEnumTranslationKey(LoanStatus, from, 'LOAN_STATUS')}/>
                </span>
                <FontAwesomeIcon icon={faArrowRight}/>
                <span className={loanStatusToColor(to, 'status status-')}>
                    <Translate id={getEnumTranslationKey(LoanStatus, to, 'LOAN_STATUS')}/>
                </span>
            </div>
        </div>
    )
}
export default ChangeStatus;