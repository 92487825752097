import { JobStatus } from '../../applications/applicant/models/JobStatus';

export const getJobStatuses = () => [
        JobStatus.Incapable,
        JobStatus.PrivateSector,
        JobStatus.PublicSector,
        JobStatus.Rehabilitation,
        JobStatus.Retired,
        JobStatus.SelfEmployed,
        JobStatus.Student,
        JobStatus.Unemployed,
        JobStatus.FixedTime
    ];
