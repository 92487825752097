import React, {FunctionComponent, useState} from "react";
import {Button} from "react-bootstrap";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import {putCampaign} from "../../../api/putCampaign";
import {launchCampaign} from "../../../api/launchCampaign";
import {CommandResult, handleCommandResult} from "../../../../common/helpers/CommandResult";
import {bindActionCreators, Dispatch} from "redux";
import {showToastMessage, ShowToastMessageProps} from "../../../../common/actions/ToastMessagesActionCreator";
import {CampaignViewDataActionCreators, CampaignViewDataActions} from "../../../actions/CampaignViewDataActionCreators";
import {connect} from "react-redux";
import {CampaignViewDataState} from "../../../reducers/campaignViewDataReducer";
import {SettingsProps} from "../../../../common/interfaces/SettingsProps";
import {createCampaign} from "../../../api/createCampaign";
import {useNavigationConfirmation} from "../../../../common/helpers/useNavigationConfirmation";
import {NavigateFunction, useNavigate} from "react-router";
import {CampaignValidationErrors, validateCampaign} from "../helpers/validators";
import {getCustomerCommunicationConfig} from "../../../../common/helpers/settingsHelpers";
import {Campaign, LaunchCampaignModel} from "../../../../common/models/Campaign";
import LaunchCampaignModal from "./LaunchCampaignModal";
import {Optional} from "utility-types";

type CampaignViewActionsStateProps = CampaignViewDataState & SettingsProps & LocalizeContextProps;

type CampaignViewActionsDispatchProps = CampaignViewDataActions & ShowToastMessageProps;

interface CampaignViewActionsProps extends CampaignViewActionsStateProps, CampaignViewActionsDispatchProps {
    createMode: boolean;
}

const CampaignViewActions: FunctionComponent<CampaignViewActionsProps> = (props) => {
    const navigate = useNavigate();
    const unblockNavigation = useNavigationConfirmation(props.campaignDirty, props.translate, undefined, undefined, () => props.setErrors({}));
    const config = getCustomerCommunicationConfig(props);
    const [showLaunchModal, setShowLaunchModal] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    
    if (!props.campaign) {
        return null;
    }
    
    const openLaunchCampaignModal = () => {
        const errorsNames = validateCampaign(props.campaign!, config, props.campaignRecipientsCount);
        if (errorsNames.length) {
            props.setErrors(errorsNames.reduce((errors, name) => ({...errors, [name]: true}), {}));
            const errorTab = 
                errorsNames[0] === CampaignValidationErrors.RECIPIENTS_ARE_REQUIRED || 
                errorsNames[0] === CampaignValidationErrors.RECIPIENTS_FILTER_IS_REQUIRED ? 'recipients' : 'design';
            props.setActiveTab(errorTab);
        } else {
            setShowLaunchModal(true);
        }
    }
    
    const saveButtonClick = () => {
        const errorsNames = validateCampaign(props.campaign!, config);
        if (errorsNames.length) {
            props.setErrors(errorsNames.reduce((errors, name) => ({...errors, [name]: true}), {}));
            props.setActiveTab('design');
        } else {
            saveCampaign(props, navigate, unblockNavigation);
        }
    }

    const sendCampaign = (campaign: LaunchCampaignModel) => {
        setShowLaunchModal(false);
        setIsSending(true);
        launchCampaign(campaign).then(result => {
            unblockNavigation();
            handleLaunch(result, props);
            const path = window.location.pathname;
            const match = path.match(/\/campaign\/(\d+)/);
            const currentId = match ? match[1] : null;
            if (result.success && currentId && parseFloat(currentId) === campaign.id) {
                navigate(`/campaign/${campaign.id}`);
            }
        });
    }
    
    return (
        <>
            {!props.createMode && 
                <>
                    <Button
                        disabled={Object.values(props.errors).some(isError => isError) || props.campaignDirty || props.isLoading || isSending}
                        variant="primary"
                        onClick={openLaunchCampaignModal}
                    >
                        <Translate id="SEND" />
                    </Button>
                </>
            }
            <Button
                disabled={props.isLoading || !props.campaignDirty}
                variant={props.createMode ? 'primary' : 'outline-primary'}
                onClick={saveButtonClick}
            >
                <Translate id="SAVE" />
            </Button>
            <Button
                disabled={props.isLoading}
                variant="outline-secondary"
                onClick={() => handleCancel(props, navigate, unblockNavigation)}
            >
                <Translate id="CANCEL" />
            </Button>
            {isLaunchableCampaign(props.campaign) &&
                <LaunchCampaignModal
                    campaign={props.campaign}
                    recipients={props.campaignRecipientsCount}
                    show={showLaunchModal}
                    onLaunched={sendCampaign}
                    onHide={() => setShowLaunchModal(false)}
                />
            }
        </>
    );
}

const isLaunchableCampaign = (campaign: Optional<Campaign, 'id' | 'name'>): campaign is Campaign => {
    return campaign.id !== undefined && campaign.name !== undefined;
}

const saveCampaign = (props: CampaignViewActionsProps, navigate: NavigateFunction, unblockNavigation: () => void) => {
    if (props.createMode) {
        createCampaign({
            ...(props.campaign! as Campaign),
            filter: props.campaign!.campaignRecipientsFilter
        }).then((result) => {
            unblockNavigation();
            navigate(`/campaign/${result.campaignId}/edit`);
            handleCreate(result, props, window.location.pathname.endsWith('/copy'));
        });
    } else if (props.campaign!.id) {
        putCampaign(props.campaign!.id, props.campaign!).then(result => {
            unblockNavigation();
            props.editCampaign({}, false);
            handleSave(result, props);
        });
    }
}

const handleCancel = (props: CampaignViewActionsProps, navigate: NavigateFunction, unblockNavigation: () => void) => {
    if (!props.campaignDirty) {
        unblockNavigation();
    }
    if (props.createMode) {
        navigate(-1);
        return;
    }
    navigate('/campaigns');
}

const handleCreate = (result: CommandResult, props: CampaignViewActionsProps, copied: boolean) => handleCommandResult(result, props, copied ? 'CAMPAIGNS_VIEW.CAMPAIGN_COPIED' : 'CAMPAIGNS_VIEW.CAMPAIGN_CREATED');

const handleSave = (result: CommandResult, props: CampaignViewActionsProps) => handleCommandResult(result, props, 'CAMPAIGNS_VIEW.CAMPAIGN_SAVED');

const handleLaunch = (result: CommandResult, props: CampaignViewActionsProps) => handleCommandResult(result, props, 'CAMPAIGNS_VIEW.CAMPAIGN_SENT');

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.campaignViewDataReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    showToastMessage,
    ...CampaignViewDataActionCreators
}, dispatch);

export default connect<CampaignViewActionsStateProps, CampaignViewActionsDispatchProps>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(CampaignViewActions));