import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import "./CalendarView.css";
import {ApplicantsFilter} from "../../models/ApplicantsFilter";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import moment from "moment";
import {getFollowUps} from "../../api/getFollowUps";
import {connect} from "react-redux";
import {UserProps} from "../../../../common/interfaces/UserProps";
import Tooltip from "../../../../common/components/Tooltip";
import Translate_i18next from "../../../../common/components/Translate_i18next";
import {FollowUp} from "../../../../common/models/FollowUp";
import {LoanStatus} from "../../../../common/models/LoanStatus";
import {FollowUpModel} from "../../../../common/models/FollowUpModel";
import {FollowUpStatus} from "../../../../common/models/FollowUpStatus";

export interface CalendarViewProps extends UserProps {
    onFilterChange: (filter: Pick<ApplicantsFilter, 'followUp' | 'followUpDateFrom' | 'followUpDateTo' | 'loanStatus'>) => void;
}
const CalendarView: FunctionComponent<CalendarViewProps> = (props) => {
    const [visible, setVisible] = useState<boolean>(false);
    const now = useMemo(() => new Date(), []);
    const [current, setCurrent] = useState<Date>(now);
    const [followUpsCalendarMap, setFollowUpsCalendarMap] = useState<Map<string, FollowUpModel[]>>(new Map<string, FollowUpModel[]>());
    
    useEffect(() => {
        if (props.userData.user && visible) {
            const from = getFirstMondayBefore(current);
            const to = getLastMondayAfter(current);
            getFollowUps(props.userData.user.id, from, to).then(res => handleData(res.data));
        }
    }, [visible, current]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleData = (followUps: FollowUpModel[]) => {
        const update = new Map<string, FollowUpModel[]>();
        followUps.forEach(f => {
            const date = getDayOfYear(f.followUpDate);
            if (update.has(date)) {
                update.get(date)!.push(f);
            } else {
                update.set(date, [f]);
            }
        });
        setFollowUpsCalendarMap(update);
    }
    
    const getDayOfYear = (date: Date) => moment(date).format('DDD');
    
    const onMonthChange = (date: Date) => {
        setCurrent(date);
    }
    
    const onFollowUpsClick = (date: Date, incomplete = true) => {
        setVisible(false);
        props.onFilterChange({
            followUp: FollowUp.Custom,
            followUpDateTo: moment(date).endOf('day').toDate(),
            followUpDateFrom: moment(date).startOf('day').toDate(),
            loanStatus: incomplete ? LoanStatus.Incomplete : null
        });
    }
    
    const isCurrentMonth = (date: Date) => {
        const inputDate = moment(date);
        const currentMonth = moment(current);

        return inputDate.year() === currentMonth.year() && inputDate.month() === currentMonth.month();
    }
    
    const renderDailyFollowUps = (day?: Date) => {
        if (!day) {
            return null;
        }
        const dailyFollowUps = followUpsCalendarMap.get(getDayOfYear(day));
        const incompleteFollowUps = dailyFollowUps?.filter(f => f.status === FollowUpStatus.Incomplete).length || 0;
        const completeFollowUps = (dailyFollowUps?.length || 0) - incompleteFollowUps;
        return (
            <div
                className="daily-follow-ups"
            >
                {moment(day).date()}
                {incompleteFollowUps > 0 &&
                    <div
                        className="status status-lightgoldenrodyellow incomplete"
                        onClick={() => onFollowUpsClick(day)}
                    >
                        <Translate_i18next id="INCOMPLETE_FOLLOW_UPS" data={{count: incompleteFollowUps}}/>
                    </div>
                }
                {completeFollowUps > 0 &&
                    <div 
                        className="status status-gray complete"
                        onClick={() => onFollowUpsClick(day, false)}
                    >
                        <Translate_i18next id="COMPLETE_FOLLOW_UPS" data={{count: completeFollowUps}}/>
                    </div>
                }
            </div>
        )
    }

    if (!props.userData.user) {
        return null;
    }
    return (
        <>
            <Tooltip content={<Translate_i18next id="CALENDAR_VIEW"/>}>
                <FontAwesomeIcon icon={faCalendar} onClick={() => setVisible(true)}/>
            </Tooltip>
            <Modal
                className="calendar-view"
                show={visible}
                centered={true}
                onHide={() => setVisible(false)}
            >
                <Modal.Body>
                    <DatePicker
                        selected={null}
                        onChange={() => { /* noop */ }}
                        onMonthChange={onMonthChange}
                        inline
                        dayClassName={day => !isCurrentMonth(day) ? 'out-of-month' : ''}
                        renderDayContents={(_, day) => renderDailyFollowUps(day)}
                    /> 
                </Modal.Body>
            </Modal>
        </>
    )
}

const getFirstMondayBefore = (day: Date) => {
    const startOfMonth = moment(day).startOf('month');
    const closestMonday = startOfMonth.clone().day(1);
    if (closestMonday.isAfter(startOfMonth)) {
        closestMonday.day(-7);
    }
    return closestMonday.toDate();
}

const getLastMondayAfter = (day: Date) => {
    const endOfMonth = moment(day).endOf('month').add(1, 'day').startOf('day');
    const closestMonday = endOfMonth.clone().day(1);
    if (closestMonday.isBefore(endOfMonth)) {
        closestMonday.day(7);
    }
    return closestMonday.toDate();
}

const mapStateToProps = (state: any) => ({
    ...state.userActionsReducer
});

export default connect<UserProps, {}, {}, any>(mapStateToProps)(CalendarView);