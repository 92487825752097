import React, {FunctionComponent, ReactNode, useEffect, useState} from 'react';
import { Translate } from 'react-localize-redux';
import DateTimeFormatter, { DateTimeFormat } from '../../../common/components/DateTimeFormatter';
import { getLoanStatusTranslateKey } from '../../../common/helpers/loanStatusFunctions';
import { LoanStatus } from '../../../common/models/LoanStatus';
import { ConsumerLoanBrief } from '../models/ConsumerLoanBrief';
import './loan-picker.css';
import { LazyScrollContainer } from '../../../common/components/LazyScrollContainer';
import { loanStatusToColor } from '../../../common/helpers/loanStatusToColor';
import ProductIcon from '../../../common/components/ProductIcon';
import { useLocalStorage } from 'usehooks-ts';
import { ProductType } from '../../../common/models/ProductType';
import { getEnumTranslationKey } from '../../../common/helpers/getTranslationKey';

interface LoanPickerProps {
    loans: ConsumerLoanBrief[];
    selectedLoanId?: number;
    scopeSourceId?: number | null;
    hideFilter?: boolean;
    onLoanPicked?(loanId: number): void;
}

interface LoanPickerState {
    maxCount: number;
}

interface LoanPickerFilter {
    selectedProductOnly: boolean;
}

const defaultCountLimit: number = 5;
const itemWidth: number = 97;
const buttonWidth: number = 29;

function compareLoans(loan1: ConsumerLoanBrief, loan2: ConsumerLoanBrief) {
    if (loan1.submittedDate === loan2.submittedDate) {
        return loan2.id - loan1.id;
    }

    return new Date(loan2.submittedDate).valueOf() - new Date(loan1.submittedDate).valueOf();
}

const LoanPicker: FunctionComponent<LoanPickerProps> = (props) => {
    const [filter, setFilter] = useLocalStorage<LoanPickerFilter>('', () => ({ selectedProductOnly: false }));
    const [mainElement, setMainElement] = useState<HTMLDivElement | null>(null);
    const [state, setState] = useState<LoanPickerState>({maxCount: 0});
    
    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const product = props.hideFilter ? undefined : props.loans.find((l) => l.id === props.selectedLoanId)?.productType;
    const loans = filterLoans(props.loans, filter, product).sort(compareLoans);
    const initialPosition = props.selectedLoanId === undefined ? 0 : loans.map((l) => l.id).indexOf(props.selectedLoanId);

    function updateDimensions() {
        if (mainElement != null) {
            const availableCount = Math.floor((mainElement.offsetWidth - 2 * buttonWidth) / itemWidth);
            const maxCount = Math.min(availableCount, defaultCountLimit);
            if (maxCount !== state.maxCount) {
                setState({ maxCount });
            }
        }
    }

    function refCallback(element: HTMLDivElement | null) {
        setMainElement(element);
        updateDimensions();
    }

    return (
        <div ref={refCallback} className="loan-picker">
            <div className='history-filter'>{renderFilterIcon(filter, setFilter, product)}</div>
            <LazyScrollContainer 
                items={loans} 
                renderItem={(loan, index) => mapLoan(props, loan, filter, index)} 
                maxCount={state.maxCount}
                initialPosition={initialPosition}
            />
        </div>
    );
}

function mapLoan(props: LoanPickerProps, loan: ConsumerLoanBrief, filter: LoanPickerFilter, index: number): ReactNode {
    const clickable = props.onLoanPicked && (props.scopeSourceId === undefined || props.scopeSourceId === null || props.scopeSourceId === loan.sourceId);
    const clickHandler = clickable ? () => props.onLoanPicked && props.onLoanPicked(loan.id) : undefined;

    const statusClass = (loanStatus: LoanStatus): string => {
        return `status${loanStatusToColor(loanStatus, ' status-', loan.applicationFormProgress)}`;
    };

    let loanPickerItemClasses = loan.id === props.selectedLoanId ? 'loan-picker-item selected' : 'loan-picker-item';

    if (loan.hidden) {
        loanPickerItemClasses += ' hidden';
    }

    return (
        <div className={loanPickerItemClasses} key={`loan-${index}`} onClick={clickHandler} style={{minWidth: `${itemWidth}px`, cursor: clickable ? 'pointer' : 'default'}}>
            <div className={statusClass(loan.loanStatus)}>
            {renderProductIcon(loan, filter)}<Translate id={getLoanStatusTranslateKey(loan.loanStatus)} />
            </div>
            <div className="date-container">
            <DateTimeFormatter date={loan.submittedDate} format={DateTimeFormat.Date} />
            </div>
        </div>
    );
}

function filterLoans(loans: ConsumerLoanBrief[], filter: LoanPickerFilter, product?: ProductType): ConsumerLoanBrief[] {
    return product !== undefined && filter.selectedProductOnly
        ? loans.filter((loan) => loan.productType === product)
        : loans;
}

function renderProductIcon(loan: ConsumerLoanBrief, filter: LoanPickerFilter) {
    if (filter.selectedProductOnly) {
        return null;
    }

    return <ProductIcon product={loan.productType} />;
}

function renderFilterIcon(filter: LoanPickerFilter, setFilter: (LoanPickerFilter) => void, product?: ProductType): ReactNode {
    if(product === undefined) {
        return null;
    }

    const className = filter.selectedProductOnly ? 'selected' : '';
    const tooltipContent = filter.selectedProductOnly
        ? <Translate id='HISTORY_FILTER.SELECTED_PRODUCT_ONLY' data={{"product": <Translate id={getEnumTranslationKey(ProductType, product, 'PRODUCT_TYPES')} />}} />
        : <Translate id='HISTORY_FILTER.ALL_PRODUCTS' />;
        
    return <ProductIcon
        product={product}
        className={className}
        tooltipContent={tooltipContent}
        onClick={() => setFilter({ selectedProductOnly: !filter.selectedProductOnly })}
    />;
}

export default LoanPicker;
