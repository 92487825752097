import React, {FunctionComponent, useMemo} from "react";
import {Translate} from "react-localize-redux";
import DropdownInputField from "../input-fields/DropdownInputField";
import {MessageType} from "../../models/MessageType";
import {CommentTemplate, TemplatePostActionType} from "../../models/CommentTemplate";
import {getCommentTemplates} from "../../helpers/settingsHelpers";
import {SubstatusesProps} from "../../interfaces/SubstatusesProps";
import {SettingsProps} from "../../interfaces/SettingsProps";
import {UserProps} from "../../interfaces/UserProps";
import {useTranslate_i18next} from "../../useTranslate_i18next";
import TemplateAction, {TemplateActionsProps} from "./TemplateAction/TemplateAction";
import {connect} from "react-redux";
import {ApplicantDataStateProps} from "../../../applications/applicant/models/ApplicantDataProps";
import {isAdmin} from "../../helpers/isAdmin";

interface CommentTemplatesProps extends SettingsProps, UserProps, TemplateActionsProps {
    commentTemplate?: CommentTemplate
    onCommentTemplateChange?: (commentTemplate: CommentTemplate) => void;
}
const CommentTemplates: FunctionComponent<CommentTemplatesProps> = ({
    commentTemplate,
    settingsData,
    userData,
    substatusIds,
    email,
    mobileNumber,
    loanStatus,
    onCommentTemplateChange
}) => {
    const translate = useTranslate_i18next();
    const commentTemplates = useMemo(() =>
        ((getCommentTemplates({settingsData}) || []) as CommentTemplate[]).filter(template => {
            const teamTemplate = template.teamIds?.some(id => id === userData.user?.teamId);
            const userTemplate = template.userIds?.some(id => id === userData.user?.id);
            return isAdmin({userData}) || teamTemplate || userTemplate;
        }), []); // eslint-disable-line react-hooks/exhaustive-deps
    const renderCommentTemplates = () => (
        <DropdownInputField
            editMode={true}
            name="template"
            searchable={commentTemplates.length > 10}
            filterPlaceholder={translate('TYPE_TEMPLATE_NAME')}
            renderUndefinedItems
            onValueChanged={(_, __, template) => onCommentTemplateChange?.(template)}
            value={commentTemplate?.name}
            displayValue={template => template?.name || translate('SELECT_TEMPLATE')}
            keyValue={template => template.name}
            items={commentTemplates}
        />
    )
    const renderTemplatePostActions = () => {
        if (!commentTemplate || !commentTemplate.actions || !commentTemplate.actions.length) {
            return null;
        }
        return (
            <div className="template-post-actions">
                <span style={{fontWeight: 'bold'}}><Translate id="SAVING_THIS_COMMENT_WILL"/></span>
                {commentTemplate.actions.map((action, i) => (
                    <TemplateAction 
                        key={i} 
                        action={action}
                        substatusIds={substatusIds}
                        email={email}
                        mobileNumber={mobileNumber}
                        loanStatus={loanStatus}
                    />
                ))}
            </div>
        )
    }
    return (
        <div className="comment-templates">
            {renderCommentTemplates()}
            {renderTemplatePostActions()}
        </div>
    )    
}
export interface PostCommentActionsProps {
    substatusIds?: number[];
    onSubstatusesChange?: (ids: number[]) => void;
    onCommunicationTemplateChange?: (name: string, type?: MessageType) => void;
    onDenyApplication?: () => void;
}
export const callPostCommentActions = (
    commentTemplate: CommentTemplate | undefined,
    {
        substatusIds,
        onSubstatusesChange,
        onCommunicationTemplateChange,
        onDenyApplication
    }: PostCommentActionsProps
) => {
    if (commentTemplate) {
        commentTemplate.actions?.forEach(action => {
            switch (action.type) {
                case TemplatePostActionType.ADD_SUBSTATUS:
                    const postAddIds = Array.from<number>(new Set([...(substatusIds || []), ...action.data]));
                    onSubstatusesChange?.(postAddIds);
                    break;
                case TemplatePostActionType.REMOVE_SUBSTATUS:
                    const postRemoveIds = substatusIds?.filter(id => !action.data.includes(id));
                    postRemoveIds && onSubstatusesChange?.(postRemoveIds);
                    break;
                case TemplatePostActionType.SEND_EMAIL:
                    onCommunicationTemplateChange?.(action.data, MessageType.Email);
                    break;
                case TemplatePostActionType.SEND_SMS:
                    onCommunicationTemplateChange?.(action.data, MessageType.SMS);
                    break;
                case TemplatePostActionType.SEND_EMAIL_AND_SMS:
                    onCommunicationTemplateChange?.(action.data);
                    break;
                case TemplatePostActionType.DENY_APPLICATION:
                    onDenyApplication?.();
                    break;
            }
        })
    }    
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.userActionsReducer
});
export default connect<SubstatusesProps & ApplicantDataStateProps & SettingsProps & UserProps, {}, {}, any>(mapStateToProps)(CommentTemplates);