import {useTranslate} from "./useTranslate";
import {ShowToastMessageProps} from "../actions/ToastMessagesActionCreator";
import {CommandResult} from "./CommandResult";

export const useHandleCommandResult = (
    showToastMessage: ShowToastMessageProps["showToastMessage"]
) => {
    const translate = useTranslate();
    return (result: CommandResult, successKey: string, successContent?: string[] | string) => {
        if (result.success) {
            showToastMessage('success', translate(successKey).toString(), successContent || []);
        } else {
            showToastMessage('error', translate('ACTION_FAILED').toString(), result.errors.map(e => translate(e.code).toString()));
        }
    }
}