import { AxiosRequestConfig, AxiosResponse } from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { renderToStaticMarkup } from 'react-dom/server';
import { initialize, LocalizeProvider, setActiveLanguage } from 'react-localize-redux';
import { Provider } from 'react-redux';
import {Route, Routes} from 'react-router';
import { HistoryRouter as Router } from "redux-first-history/rr6";
import configureStore from './store/configureStore';
import englishTranslations from './translations/en.translations.json';
import ApplicantView from './applications/applicant/components/ApplicantView';
import ApplicantsView from './applications/applicants/components/ApplicantsView';
import { LoadingBarWrapperActionCreators } from './common/actions/LoadingBarWrapperActionCreators';
import AzureRedirect from './common/components/AzureRedirect';
import Layout from './common/components/Layout';
import { AxiosClientFactory, TaurusAxiosInterceptorConfig } from './common/helpers/AxiosClientFactory';
import './index.css';
import SettingsView from './administration/components/SettingsView';
import LoadingScreen from './common/components/LoadingScreen';
import StatusesView from './administration/components/StatusesView';
import NewApplication from './applications/applicant/components/NewApplication';
import InsuranceView from './applications/applicant/components/insurances/InsuranceView';
import {getAccount} from './common/helpers/AuthProvider';
import CampaignsView from "./campaigns/components/CampaignsView";
import CampaignView from "./campaigns/components/campaign/CampaignView";
import {createReduxHistoryContext} from "redux-first-history";
import MarketingListsView from "./marketing-lists/components/MarketingListsView";
import MarketingListView from "./marketing-lists/components/marketing-list/MarketingListView";
import AdministrationLayout from "./administration/components/AdministrationLayout";
import {initLocalization} from "./translations/i18next";
import ApplicationsPipelineView from './applications/pipeline-view/components/ApplicationsPipelineView';

if (process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY) {
    import('@microsoft/applicationinsights-web').then((ApplicationInsights) =>
    {
        const appInsights = new ApplicationInsights.ApplicationInsights({ config: {
            instrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY
        }});
        appInsights.loadAppInsights();

        const account = getAccount();
        if (account) {
            appInsights.setAuthenticatedUserContext(account.username);            
        }

    });
}

const historyContext = createReduxHistoryContext({
    history: createBrowserHistory()
});

const store = configureStore(historyContext);
export const history = historyContext.createReduxHistory(store);
const languages = [
    { name: 'English', code: 'en' }
];
const defaultLanguage = (navigator.language && languages.find((l) => navigator.language.startsWith(l.code))) || languages[0];

store.dispatch(initialize({
    languages,
    options: {
        defaultLanguage: defaultLanguage.code,
        renderToStaticMarkup
    },
    translation: englishTranslations
}));

// store.dispatch(addTranslationForLanguage(polishTranslations, 'pl'));

const activeLanguage = store.getState().localize.languages.filter((l: any) => l.active === true)[0];
store.dispatch(setActiveLanguage(activeLanguage.code));

const requestInterceptor = (requestConfig: AxiosRequestConfig, taurusConfig?: TaurusAxiosInterceptorConfig): AxiosRequestConfig => {
    if (taurusConfig && taurusConfig.silent) {
        return requestConfig;
    }

    LoadingBarWrapperActionCreators.incrementPendingRequests()(store.dispatch, store.getState);
    return requestConfig;
};

const responseInterceptor = (response: AxiosResponse<any>, taurusConfig?: TaurusAxiosInterceptorConfig): AxiosResponse<any> => {
    if (taurusConfig && taurusConfig.silent) {
        return response;
    }

    LoadingBarWrapperActionCreators.decrementPendingRequests()(store.dispatch, store.getState);
    return response;
};

AxiosClientFactory.addRequestInterceptor(requestInterceptor);

AxiosClientFactory.addResponseInterceptor(responseInterceptor);

initLocalization();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

/* IMPORTANT: When adding new routes remember to update bunny cdn edge rules to avoid confusing 404s when refreshing page */

root.render(
    <React.StrictMode>
        <AzureRedirect>
            <Provider store={store}>
                <LocalizeProvider store={store}>
                    <Router history={history}>
                        <LoadingScreen>
                            <Layout>
                                <Routes>
                                    <Route path="/" element={<ApplicantsView/>} />
                                    <Route path="/applicant/application/new" element={<NewApplication/>} />
                                    <Route path="/applicant/:applicantId/application/:applicationId/copy" element={<NewApplication/>} />
                                    <Route path="/applicant/:applicantId/application/:applicationId/edit" element={<NewApplication/>} />
                                    <Route path="/applicant/:applicantId/application/:applicationId" element={<ApplicantView/>} />
                                    <Route path="/applicant/:personId/insurances" element={<InsuranceView/>} />
                                    <Route path="/applicant/:applicantId" element={<ApplicantView/>} />
                                    <Route path="/applicants" element={<ApplicantsView/>} />
                                    <Route path="/applicants/pipeline-view" element={<ApplicationsPipelineView/>} />
                                    <Route path="/administration" element={<AdministrationLayout/>} />
                                    <Route path="/administration/statuses" element={<StatusesView/>} />
                                    <Route path="/administration/trackings" element={<StatusesView/>} />
                                    <Route path="/administration/knockout" element={<StatusesView/>} />
                                    <Route path="/administration/advertisements" element={<StatusesView/>} />
                                    <Route path="/administration/banks" element={<StatusesView/>} />
                                    <Route path="/administration/source" element={<StatusesView/>} />
                                    <Route path="/administration/commission" element={<StatusesView/>} />
                                    <Route path="/administration/settings" element={<SettingsView/>} />
                                    <Route path="/campaign/new" element={<CampaignView/>} />
                                    <Route path="/campaign/:campaignId/edit" element={<CampaignView/>} />
                                    <Route path="/campaign/:campaignId/copy" element={<CampaignView/>} />
                                    <Route path="/campaign/:campaignId" element={<CampaignView/>} />
                                    <Route path="/campaigns" element={<CampaignsView/>} />
                                    <Route path="/marketing-list/new" element={<MarketingListView/>} />
                                    <Route path="/marketing-list/:listId" element={<MarketingListView/>} />
                                    <Route path="/marketing-list/:listId/copy" element={<MarketingListView/>} />
                                    <Route path="/marketing-lists" element={<MarketingListsView/>} />
                                </Routes>
                                <div id="navigation-prompt-container"/>
                            </Layout>
                        </LoadingScreen>
                    </Router>
                </LocalizeProvider>
            </Provider>
        </AzureRedirect>
    </React.StrictMode>
);
