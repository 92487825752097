import React, {FunctionComponent, useMemo} from "react";
import Bookmarks, {IBookmarks} from "../../../../common/components/Bookmarks/Bookmarks";
import {getPipelinesBookmarks} from "../../../../common/helpers/settingsHelpers";
import {connect} from "react-redux";
import {SettingsProps} from "../../../../common/interfaces/SettingsProps";
import {ApplicationsPipelineFilter} from "../ApplicationsPipeline/helpers/useApplicationsPipelineFilters";
import {ApplicationsPipelineSorting} from "../ApplicationsPipeline/helpers/useApplicationsPipelineSorting";

interface ApplicationsPipelineState {
    pipeline?: string;
    sorting?: ApplicationsPipelineSorting;
    filter?: ApplicationsPipelineFilter;
}

interface ApplicationsPipelineBookmarksProps extends ApplicationsPipelineState, SettingsProps {
    onPipelineChange?: (name?: string) => void;
    onSortingChange?: (sorting?: ApplicationsPipelineSorting) => void;
    onFilterChange?: (filter?: ApplicationsPipelineFilter) => void;
}
const ApplicationsPipelineBookmarks: FunctionComponent<ApplicationsPipelineBookmarksProps> = ({
    settingsData,
    pipeline,
    filter,
    sorting,
    onPipelineChange,
    onSortingChange,
    onFilterChange
}) => {
    const commonBookmarks: IBookmarks<ApplicationsPipelineState> = useMemo(() => getPipelinesBookmarks({settingsData}), [settingsData]);
    
    const onActiveChange = ({pipeline, filter, sorting}: ApplicationsPipelineState) => {
        onPipelineChange?.(pipeline);    
        onFilterChange?.(filter);    
        onSortingChange?.(sorting);    
    }
    
    return (
        <Bookmarks<ApplicationsPipelineState>
            storageKey="pipelines"
            active={{
                pipeline,
                filter,
                sorting
            }}
            common={commonBookmarks}
            onActiveChange={onActiveChange}
        />
    )
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer
});
export default connect<SettingsProps, null>(mapStateToProps)(ApplicationsPipelineBookmarks);