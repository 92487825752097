import { ProductType } from "../models/ProductType";
import React, {FunctionComponent} from "react";
import {faCreditCard, faHouse, faMoneyBills} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "./Tooltip";
import {Translate} from "react-localize-redux";
import {getEnumTranslationKey} from "../helpers/getTranslationKey";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ProductIconProps {
    product?: ProductType;
    tooltipContent?: string | React.ReactNode;
    className?: string;
    onClick?: () => void;
}
const ProductIcon: FunctionComponent<ProductIconProps> = (props) => {
    let icon = faMoneyBills;
    switch (props.product) {
        case ProductType.CreditCard:
            icon = faCreditCard;
            break;
        case ProductType.Mortgage:
            icon = faHouse;
            break;
    }
 
    return (
        <Tooltip content={props.tooltipContent ?? <Translate id={getEnumTranslationKey(ProductType, props.product, 'PRODUCT_TYPES')}/>} >
            <FontAwesomeIcon icon={icon} className={props.className} onClick={props.onClick}/>
        </Tooltip>
    )
}
export default ProductIcon;