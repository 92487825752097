import React, {FunctionComponent, ReactNode} from "react";
import {LoanStatus} from "../../../../../common/models/LoanStatus";
import {useTranslate} from "../../../../../common/helpers/useTranslate";
import {loanStatusToColor} from "../../../../../common/helpers/loanStatusToColor";
import {getLoanStatusTranslateKey} from "../../../../../common/helpers/loanStatusFunctions";

interface TitleProps {
    label?: string;
    status: LoanStatus;
    count: number;
    sorting?: ReactNode;
}
const Title: FunctionComponent<TitleProps> = ({
    label,
    status,
    count,
    sorting
}) => {
    const translate = useTranslate();    
    
    return (
        <div className={loanStatusToColor(status, 'status status-')}>
            <span style={{whiteSpace: 'nowrap'}}>
                {label || translate(getLoanStatusTranslateKey(status))} ({count})
            </span>
            {sorting}
        </div>
    )
}
export default Title;