import {TimeUnit} from "../models/TimeUnit";
import {NumberInputFieldKind} from "../components/input-fields/NumberInputField";

export const timeUnitToNumberKind = (unit?: TimeUnit | null): NumberInputFieldKind | undefined => {
    switch (unit) {
        case TimeUnit.Days:
            return NumberInputFieldKind.Days;
        case TimeUnit.Months:
            return NumberInputFieldKind.Months;
    }
}

export const numberKindToTimeUnit = (kind?: NumberInputFieldKind | null): TimeUnit | undefined => {
    switch (kind) {
        case NumberInputFieldKind.Days:
            return TimeUnit.Days;
        case NumberInputFieldKind.Months:
            return TimeUnit.Months;
    }
}