import {ApplicationPipelineModel} from "./ApplicationPipelineModel";
import {PipelineStageFilterDefinition} from "./configuration/PipelineStageFilterDefinition";
import {LoanStatus} from "../../../common/models/LoanStatus";

export class PipelineStage {
    name: string;
    isEndStage: boolean;
    filter: PipelineStageFilterDefinition;
    applications: ApplicationPipelineModel[] = [];
    total: number = 0;
    
    constructor(name: string, filter: PipelineStageFilterDefinition) {
        this.name = name;
        this.filter = filter;
        this.isEndStage = filter.status === LoanStatus.Denied || filter.status === LoanStatus.Paid;
    }
    
    public clear() {
        this.applications = [];
        this.total = 0;
    }

    public calculateMatchScore(application: ApplicationPipelineModel): number {
        let score = 0;

        if (this.filter.status !== undefined) {
            if (application.status === this.filter.status) {
                score++;
            } else {
                return 0;
            }
        }

        if (this.filter.substatusIds) {
            if (this.filter.substatusIds.every(id => application.substatusIds.includes(id))) {
                score += this.filter.substatusIds.length;
            } else {
                return 0;
            }
        }

        if (this.filter.sentToBanks) {
            if (application.sentToBanks) {
                score++;
            } else {
                return 0;
            }
        }

        return score;
    }

    public assignApplication(application: ApplicationPipelineModel): void {
        this.applications.push(application);
    }

    public completeAssignment(): void {
        this.total = this.calculateTotal();
    }

    private calculateTotal(): number {
        return this.applications.reduce((total, application) => total + this.getApplicationValue(application), 0);
    }

    private getApplicationValue(application: ApplicationPipelineModel): number {
        return application.bankAmount || application.appliedAmount || 0;
    }
}