import React, {FunctionComponent, SVGAttributes} from "react";
import Tooltip from "../Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {OrderDirection} from "../../models/TableState";
import {faSort, faSortAsc, faSortDesc} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "react-bootstrap";
import {StageProps, StageSorter} from "./Stage";
import {Translate} from "react-localize-redux";

interface SortingToggleProps {
    activeSorter?: StageSorter<any>;
    onToggle: () => void;
}

const SortingToggle = React.forwardRef<any, SortingToggleProps & SVGAttributes<SVGSVGElement>>((
    {
        activeSorter,
        onToggle,
        onClick
    },
    ref
) => (
    <Tooltip content={
        <span>
            {activeSorter?.label || <Translate id="NO_SORTING"/>}
            <br/>
            <Translate id="RIGHT_CLICK_TO_EDIT"/>
        </span>
    }>
        <FontAwesomeIcon
            ref={ref}
            className={`icon ${!activeSorter?.editable ? 'disabled' : ''}`}
            icon={activeSorter ? (activeSorter.order === OrderDirection.Descending ? faSortDesc : faSortAsc) : faSort}
            onClick={onToggle}
            onContextMenu={e => {
                onClick?.(e);
                e.preventDefault();
            }}
        />
    </Tooltip>
));

interface SortingProps extends SortingToggleProps, Pick<StageProps<any>, 'sorters'> {
    onSorterChange: (sorter?: StageSorter<any>) => void;
}

const Sorting: FunctionComponent<SortingProps> = ({
    activeSorter,
    sorters,
    onToggle,
    onSorterChange
}) => {
    return (
        <Dropdown drop="down" align="end" className="sorting">
            <Dropdown.Toggle
                as={SortingToggle}
                activeSorter={activeSorter}
                onToggle={onToggle}
            />
            <div className="dropdown-arrow-down" />
            <Dropdown.Menu>
                {sorters?.map(sorter => (
                    <Dropdown.Item active={activeSorter?.label === sorter.label} key={sorter.label} onClick={() => onSorterChange(sorter)}>
                        {sorter.label}
                    </Dropdown.Item>
                ))}
                <Dropdown.Divider key="divider"/>
                <Dropdown.Item key="clear" onClick={() => onSorterChange(undefined)}>
                    <Translate id="CLEAR_SORTING"/>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}
export default Sorting;