import {IconProp} from "@fortawesome/fontawesome-svg-core";
import React, {FunctionComponent} from "react";
import Tooltip from "../../../../../common/components/Tooltip";
import Translate_i18next from "../../../../../common/components/Translate_i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface DetailProps {
    icon?: IconProp;
    descriptionKey: string;
}
const Detail: FunctionComponent<DetailProps> = ({
    icon,
    descriptionKey,
    children
}) => (
    <Tooltip content={<Translate_i18next id={descriptionKey}/>} placement="right">
        <div className="detail">
            {icon ? <FontAwesomeIcon icon={icon}/> : null}
            {children}
        </div>
    </Tooltip>
)
export default Detail;