import React, {PropsWithChildren, ReactNode} from "react";
import "./Pipeline.css";
import Filters from "./Filters";

interface PipelineProps {
    filters?: ReactNode;
}
const Pipeline = ({
    filters,
    children
}: PropsWithChildren<PipelineProps>) => (
    <div className="pipeline">
        {filters}
        <div className="stages">
            {children}
        </div>
    </div>
)

type TPipelineComponent = React.FC<PipelineProps> & { Filters: typeof Filters };

const PipelineComponent: TPipelineComponent = Pipeline as TPipelineComponent;
PipelineComponent.Filters = Filters;

export default PipelineComponent;