import { CSSProperties } from 'react';

export default function getProgressCompletionStyling(value = 0): CSSProperties {
    const baseStyle: CSSProperties = { padding: '0px 4px', borderRadius: '10px' };
    if (value >= 83) {
        return { ...baseStyle, backgroundColor: '#42D253', color: '#ffffff' };
    }
    if (value >= 50) {
        return { ...baseStyle, backgroundColor: '#559AF4', color: '#ffffff' };
    }
    if (value >= 33) {
        return { ...baseStyle, backgroundColor: '#FF9900', color: '#ffffff' };
    }

    return { ...baseStyle, backgroundColor: '#EEEEEE', color: '#9EA8B2' };
}
