import { ConsumerLoan } from '../../models/ConsumerLoan';
import { PropertiesSummary } from '../../models/PropertiesSummary';

interface BankValidator {
    bankId: number;
    validate: (application: ConsumerLoan) => string | null;
}

const instabankMortgageValidation = (application: ConsumerLoan): string | null => {
    if (!testPropertyRequirements(application.propertiesSummary)) {
        return 'SEND_VALIDATION.MISSING_PROPERTY_DATA';
    }
    
    return null;
};

function testPropertyRequirements(property: PropertiesSummary | null): property is PropertiesSummary {
    return property !== null && property.totalEstimatedValue !== null && property.totalEstimatedValue > 0;
}

const bankValidators: BankValidator[] = [
    { bankId: 9995, validate: instabankMortgageValidation }
];

export const validateApplication = (application: ConsumerLoan | undefined, bankId: number): string | null => {

    const bankValidator = bankValidators.find((bv) => bv.bankId === bankId);

    if (bankValidator && application) {
        return bankValidator.validate(application);
    }

    return null;
};
