import React, {FunctionComponent} from "react";
import "./ApplicantsViewPicker.css";
import {ApplicantsGroupBy} from "../../models/ApplicantsGroupBy";
import Tooltip from "../../../../common/components/Tooltip";
import {Translate} from "react-localize-redux";
import {getEnumTranslationKey} from "../../../../common/helpers/getTranslationKey";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSortAmountUpAlt, faTag, faUser, faUserTag} from "@fortawesome/free-solid-svg-icons";
import CalendarView, {CalendarViewProps} from "../CalendarView/CalendarView";

interface ApplicantsViewPickerProps extends Pick<CalendarViewProps, 'onFilterChange'>{
    active: ApplicantsGroupBy | 'pipeline';
    onChange: (active: ApplicantsGroupBy | 'pipeline') => void;
}
const ApplicantsViewPicker: FunctionComponent<ApplicantsViewPickerProps> = ({
    active,
    onChange,
    onFilterChange
}) => {
    return (
        <div className="applicants-view-picker">
            {[
                ApplicantsGroupBy.ByApplicantAndProduct,
                ApplicantsGroupBy.None,
                ApplicantsGroupBy.ByApplicant
            ].map(groupBy => (
                <Tooltip
                    key={groupBy}
                    content={
                        <Translate
                            id={getEnumTranslationKey(ApplicantsGroupBy, groupBy, 'APPLICANTS_GROUP_BY')}
                        />
                    }
                >
                    <button
                        className={`btn ${active === groupBy ? 'active' : ''}`}
                        onClick={() => onChange(groupBy)}
                    >
                        <FontAwesomeIcon icon={(() => {
                            switch (groupBy) {
                                case ApplicantsGroupBy.ByApplicantAndProduct:
                                    return faUserTag;
                                case ApplicantsGroupBy.ByApplicant:
                                    return faUser;
                                case ApplicantsGroupBy.None:
                                    return faTag;
                                default:
                                    return faUserTag;
                            }
                        })()}/>
                    </button>
                </Tooltip>
            ))}
            <Tooltip
                key="pipeline"
                content={<Translate id="APPLICATIONS_PIPELINE_VIEW.LABEL"/>}
            >
                <button
                    className={`btn ${active === 'pipeline' ? 'active' : ''}`}
                    onClick={() => onChange('pipeline')}
                >
                    <FontAwesomeIcon icon={faSortAmountUpAlt} rotation={90}/>
                </button>
            </Tooltip>
            <button className="btn">
                <CalendarView onFilterChange={onFilterChange}/>
            </button>
        </div>
    )
}
export default ApplicantsViewPicker;