import React, {CSSProperties, ReactNode, SetStateAction, useEffect, useState} from 'react';
import "./input-field.css";
import InputField, {InputFieldProps} from './InputField';
import {Dropdown, FormControl, InputGroup} from 'react-bootstrap';
import {getCurrencySymbolForCountryId} from '../../helpers/currencySymbolFunctions';
import {LocalizeContextProps, withLocalize} from 'react-localize-redux';
import {useValidation, ValidationProps} from '../../helpers/useValidation';
import {isValueSet} from "../../helpers/isValueSet";
import {connect} from "react-redux";
import {SettingsProps} from "../../interfaces/SettingsProps";
import {getCountryId} from "../../helpers/settingsHelpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";

const MIN_INT = -2147483648;
const MAX_INT = 2147483647;

export enum NumberInputFieldKind {
    Money,
    Percent,
    Years,
    Months,
    Days,
    MoneyPerMonth,
    MoneyPerYear,
    NumberOf,
    SquareMetres
}

interface NumberInputFieldProps extends InputFieldProps, ValidationProps, LocalizeContextProps, SettingsProps {
    name: string;
    value: number | null | undefined;
    readonlyValuePostFix?: string | ReactNode;
    kind?: NumberInputFieldKind;
    kinds?: NumberInputFieldKind[];
    min?: number;
    max?: number;
    step?: number;
    disabled?: boolean;
    nullable?: boolean;
    readonlyPrecision?: number;
    onValueChanged?: (value: number | null, name: string) => void;
    onBlur?: (value: number | null, dirty: boolean) => void;
    onKindChanged?: (kind: NumberInputFieldKind) => void;
}

function NumberInputField<WrapperProps>(props: NumberInputFieldProps) {
    const [errorCode, internalError, ref, onTouched] = useValidation(props);
    const [tempValue, setTempValue] = useState(getTempValue(props));
    const [dirty, setDirty] = useState(false);
    useEffect(() => setTempValue(getTempValue(props)), [props.value, props.editMode]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        // react changes input value after scroll inside input
        // this is a code to disable this
        // https://github.com/facebook/react/issues/24986
        ref.current?.addEventListener("wheel", e => {
            e.preventDefault();
            e.stopImmediatePropagation();
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <InputField<WrapperProps>
            inputRef={ref}
            className={props.className}
            afterContent={props.afterContent}
            descriptionKey={props.descriptionKey}
            description={props.description}
            editMode={props.editMode}
            clearable={props.nullable ? isValueSet(props.value) : !!props.value}
            error={internalError}
            errorCode={errorCode}
            errorCodesData={props.errorCodesData}
            wrapper={props.wrapper}
            wrapperProps={props.wrapperProps}
            onClear={() => {
                onTouched();
                props.onValueChanged && props.onValueChanged(props.nullable ? null : 0, props.name)
            }}
        >
            {renderValue(props, ref, onTouched, tempValue, setTempValue, dirty, setDirty)}
        </InputField>
    );
};

function renderValue(
    props: NumberInputFieldProps,
    ref: React.RefObject<HTMLInputElement>,
    onTouched: () => void,
    tempValue: string,
    setTempValue: React.Dispatch<SetStateAction<string>>,
    dirty: boolean,
    setDirty: React.Dispatch<SetStateAction<boolean>>) {

    if (!props.editMode) {
        if (hasEmptyValue(props)) {
            return <span>-</span>;
        }
        let formattedValue = props.value!;
        if (props.readonlyPrecision !== undefined) {
            formattedValue = parseFloat(props.value!.toFixed(props.readonlyPrecision));
        }

        return <span>{formattedValue.toLocaleString()} {renderUnitOfMeasure(props)} {renderReadonlyValuePostfix(props)}</span>;
    }

    const onValueChanged = (e: any) => {
        setDirty(true);
        const value = getValue(e.target.value, props.nullable);

        if (value && (value > MAX_INT || value < MIN_INT)) {
            return;
        }

        let parsable;
        try {
            Number.parseFloat(value);
            parsable = true;
        } catch (error) {
            parsable = false;
        }

        setTempValue(value !== null ? value.toString() : '');
        const newNumber = value !== null ? Number(value) : null;
        onTouched();
        if (props.onValueChanged && parsable) {
            props.onValueChanged(newNumber, props.name);
        }
    };

    const onBlur = props.onBlur !== undefined ? (e: any) => props.onBlur!(Number(getValue(e.target.value, props.nullable)), dirty) : undefined;

    return (
        <InputGroup>
            <FormControl
                name={props.name}
                type="number"
                value={getDisplayValue(tempValue, props.nullable)}
                onChange={onValueChanged}
                min={props.min || 0}
                max={props.max}
                step={props.step}
                disabled={props.disabled}
                ref={ref as any}
                placeholder={props.placeholderKey && props.translate(props.placeholderKey).toString()}
                onBlur={onBlur}
            />
            {isValueSet(props.kind) &&
                <InputGroup.Text>{renderUnit(props)}</InputGroup.Text>
            }
        </InputGroup>
    );
}

function renderUnit(props: NumberInputFieldProps) {
    if (!props.kinds?.length || !isValueSet(props.kind) || !props.kinds.includes(props.kind)) {
        return renderUnitOfMeasure(props);
    }
    const toggleStyle: CSSProperties = {
        padding: 0,
        background: 'transparent',
        border: 'none',
        fontSize: '14px',
        color: '#212529',
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
    }
    return (
        <Dropdown drop="down" align="end">
            <Dropdown.Toggle id="application-menu" style={toggleStyle}>
                {renderUnitOfMeasure(props)}
                <FontAwesomeIcon icon={faAngleDown} />
            </Dropdown.Toggle>
            <div className="dropdown-arrow-down" />
            <Dropdown.Menu>
                {props.kinds.map(kind => (
                    <Dropdown.Item key={kind} onClick={() => props.onKindChanged?.(kind)}>
                        {renderUnitOfMeasure(props, kind)}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

function getValue(inputValue: any, nullable?: boolean) {
    if (inputValue === undefined || inputValue === null || inputValue === '') {
        if (nullable) {
            return null;
        }

        return '0';
    }

    return inputValue;
}

function getDisplayValue(tempValue: string, nullable?: boolean) {
    if (tempValue === '' && nullable) {
        return '';
    }

    let displayValue: string;
    try {
        if (tempValue.indexOf('.') > -1) {
            displayValue = tempValue;
        } else {
            displayValue = Number(tempValue).toString();
        }
    } catch (error) {
        displayValue = tempValue;
    }
    return displayValue;
}

function renderUnitOfMeasure(props: NumberInputFieldProps, kind?: NumberInputFieldKind) {
    switch (isValueSet(kind) ? kind : props.kind) {
        case NumberInputFieldKind.Money:
            return getCurrencySymbolForCountryId(getCountryId(props));
        case NumberInputFieldKind.Percent:
            return '%';
        case NumberInputFieldKind.Years:
            return props.translate('YEARS');
        case NumberInputFieldKind.Months:
            return props.translate('MONTHS');
        case NumberInputFieldKind.Days:
            return props.translate('DAYS');
        case NumberInputFieldKind.MoneyPerMonth:
            return `${getCurrencySymbolForCountryId(getCountryId(props))}/${props.translate('MONTH_ABBREVIATION')}`;
        case NumberInputFieldKind.MoneyPerYear:
            return `${getCurrencySymbolForCountryId(getCountryId(props))}/${props.translate('YEAR_ABBREVIATION')}`;
        case NumberInputFieldKind.NumberOf:
            return props.translate('NUMBER_ABBREVIATION');
        case NumberInputFieldKind.SquareMetres:
            return <React.Fragment>m<sup>2</sup></React.Fragment>;
        default:
            return null;
    }
}

function renderReadonlyValuePostfix(props: NumberInputFieldProps) {
    if (props.readonlyValuePostFix === undefined) {
        return null;
    }

    return (props.readonlyValuePostFix);
}

function hasEmptyValue(props: NumberInputFieldProps) {
    return (props.value === null || props.value === undefined);
}

function getTempValue(props: NumberInputFieldProps) {
    if (props.value === undefined || props.value === null) {
        if (props.nullable) {
            return '';
        }

        return '0';
    }
    return props.value.toString();
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer
});


export default connect<SettingsProps>(mapStateToProps)(withLocalize(NumberInputField));
