import { PipelineDefinition } from "./configuration/PipelineDefinition";
import { Pipeline } from "./Pipeline";
import { PipelineStage } from "./PipelineStage";

export const createPipeline = (config: PipelineDefinition): Pipeline =>
    new Pipeline(
        config.name,
        config.filter,
        config.stages.map(s =>      
            new PipelineStage(s.name, s.filter)
        )
    );
