import React, {ReactNode, useEffect, useMemo, useState} from "react";
import {OrderDirection} from "../../models/TableState";
import Sorting from "./Sorting";
import {Button} from "react-bootstrap";
import Translate_i18next from "../Translate_i18next";


type CompareFn<T> = (item1: T, item2: T) => number;
export interface StageSorter<T> {
    id: keyof T;
    label: string;
    default?: boolean;
    order?: OrderDirection; // default Ascending
    editable?: boolean;
    visible?: (...args: any[]) => boolean;
    compare: (order?: OrderDirection) => CompareFn<T>;
}

export interface StageProps<T> {
    data: T[];
    title: (count: number, sorting?: ReactNode) => ReactNode;
    isEndStage?: boolean;
    render?: (item: T) => ReactNode;
    summary?: (data: T[]) => ReactNode;
    sorters?: StageSorter<T>[];
    loadMoreData?: () => void;
    onSorterChange?: (active?: StageSorter<T>) => void;
}

const Stage = <T,>({
    title,
    isEndStage,
    data,
    render,
    summary,
    sorters,
    loadMoreData,
    onSorterChange
}: StageProps<T>) => {
    const [activeSorter, setActiveSorter] = useState<StageSorter<T> | undefined>();
    const sortedData = useMemo(() => {
        if (!activeSorter) {
            return data;
        }
        return data.sort(activeSorter.compare(activeSorter.order));
    }, [activeSorter, data]);

    useEffect(() => {
        setActiveSorter(sorters?.find(sorter => sorter.default));
    }, [sorters, setActiveSorter]);
    
    const onActiveSorterChange = (sorter?: StageSorter<T>) => {
        setActiveSorter(sorter);
        onSorterChange?.(sorter);
    }
    
    const sorting = (
        <Sorting
            sorters={sorters}
            activeSorter={activeSorter}
            onToggle={() => {
                if (activeSorter?.editable) {
                    onActiveSorterChange({
                        ...activeSorter!,
                        order: activeSorter!.order === OrderDirection.Ascending ? OrderDirection.Descending : OrderDirection.Ascending
                    })
                }
            }}
            onSorterChange={onActiveSorterChange}
        />
    )
    
    return (
        <div className={`stage ${isEndStage ? 'end-stage' : ''}`}>
            <div className="title">
                {title(data.length, sorters?.length && data.length ? sorting : undefined)}
            </div>
            <div className="items">
                {sortedData.map(item => render?.(item) || item)}
                {loadMoreData ?
                    <Button
                        variant="link"
                        className="load-more-data"
                        onClick={loadMoreData}
                    >
                        <Translate_i18next id={'LOAD_MORE_DATA'}/>
                    </Button> : null
                }
            </div>
            <div className="summary">
                {summary?.(data)}
            </div>
        </div>
    )
}
export default Stage;