import React, {FunctionComponent, MouseEventHandler, ReactNode, useEffect, useMemo, useRef, useState} from "react";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import TextInputField from "../../input-fields/TextInputField";
import {Translate} from "react-localize-redux";
import Tooltip from "../../Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentMedical} from "@fortawesome/free-solid-svg-icons";
import CommentTemplates, {
    callPostCommentActions, PostCommentActionsProps
} from "../../CommentTemplates/CommentTemplates";
import {CommentTemplate} from "../../../models/CommentTemplate";
import {TemplateActionsProps} from "../../CommentTemplates/TemplateAction/TemplateAction";

interface AddCommentActionProps extends PostCommentActionsProps, TemplateActionsProps {
    trigger?: (ref: React.Ref<any>, onClick: MouseEventHandler) => ReactNode;
    onSave: (comment: string) => void;
}
const AddCommentAction: FunctionComponent<AddCommentActionProps> = (props) => {
    const [comment, setComment] = useState<string | undefined>();
    const [commentTemplate, setCommentTemplate] = useState<CommentTemplate>();
    // retrieve toggle handler to close overlay after popover button click
    const toggleOverlay = useRef<() => void>(() => {/*no-op*/ });
    const allowCommentTemplates = useMemo(() => (
        props.onDenyApplication && 
        props.onCommunicationTemplateChange &&
        props.onSubstatusesChange
    ), [props.onDenyApplication, props.onCommunicationTemplateChange, props.onSubstatusesChange]);
    
    useEffect(() => {
        if (commentTemplate) {
            setComment(commentTemplate.content);
        }
    }, [commentTemplate, setComment]);
    
    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Popover style={{maxWidth: 'fit-content', minWidth: '300px'}}>
                    <Popover.Body onClick={e => e.stopPropagation()}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                            {allowCommentTemplates ?
                                <CommentTemplates
                                    commentTemplate={commentTemplate}
                                    substatusIds={props.substatusIds}
                                    email={props.email}
                                    mobileNumber={props.mobileNumber}
                                    loanStatus={props.loanStatus}
                                    onCommentTemplateChange={setCommentTemplate}
                                /> : null
                            }
                            <TextInputField
                                name="customer-comment"
                                value={comment}
                                onValueChanged={setComment}
                                editMode={true}
                                rows={4}
                            />
                            <Button
                                disabled={!comment}
                                size="sm"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    props.onSave(comment!);
                                    if (allowCommentTemplates) {
                                        callPostCommentActions(commentTemplate, props);
                                        setCommentTemplate(undefined);
                                    }
                                    toggleOverlay.current();
                                }}
                            >
                                <Translate id="SAVE" />
                            </Button>
                        </div>
                    </Popover.Body>
                </Popover>
            }
            trigger="click"
            rootClose={true}
            onToggle={() => setComment(undefined)}
        >
            {/*render overlay target by function to get OT props with toggle handler*/}
            {/*see https://react-bootstrap.netlify.app/docs/components/overlays/#customizing-trigger-behavior*/}
            {
                overlayProps => {
                    let toggleOverlayHandler = (overlayProps as any).onClick;
                    toggleOverlay.current = toggleOverlayHandler;
                    return (
                        props.trigger ? props.trigger(overlayProps.ref, toggleOverlayHandler) : (
                            <Tooltip content={<Translate id="ADD_COMMENT" />}>
                                <FontAwesomeIcon
                                    ref={overlayProps.ref}
                                    icon={faCommentMedical}
                                    onClick={toggleOverlayHandler}
                                />
                            </Tooltip>
                        )                            
                    )
                }
            }
        </OverlayTrigger>
    )
}
export default AddCommentAction;