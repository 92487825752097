import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {MultiSelect} from '../MultiSelect';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {LocalizeContextProps, withLocalize} from 'react-localize-redux';
import {CustomerAdvisor} from "../../../applications/applicant/models/CustomerAdvisor";
import CustomerAdvisorsTeamsActionsCreator, {CustomerAdvisorsTeamsActions} from "../../../applications/applicants/actions/CustomerAdvisorsTeamsActionsCreator";
import {CustomerAdvisorsTeamsProps} from "../../interfaces/CustomerAdvisorsTeamsProps";
import {UserProps} from '../../interfaces/UserProps';
import {isAdmin} from "../../helpers/isAdmin";
import {isValueSet} from "../../helpers/isValueSet";

interface CustomerAdvisorsInputFieldProps {
    className?: string;
    customerAdvisorIds?: number[];
    onCustomerAdvisorsChanged?: (ids: number[]) => void;
    teamScopeId?: number | null;
    placeholder?: string;
    placeholderKey?: string;
    description?: string;
    descriptionKey?: string;
    editMode?: boolean;
}

const CustomerAdvisorsInputField: FunctionComponent<CustomerAdvisorsInputFieldProps & CustomerAdvisorsTeamsProps & CustomerAdvisorsTeamsActions & UserProps & LocalizeContextProps> = (props) => {
    const [customerAdvisors, setCustomerAdvisors] = useState(getCustomerAdvisors(props));
    const [selectedCustomerAdvisors, setSelectedCustomerAdvisors] = useState<CustomerAdvisor[]>([]);
    const updateCustomerAdvisors = useCallback(() => {
        const customerAdvisors = getCustomerAdvisors(props)
        setCustomerAdvisors(customerAdvisors);
    }, [props, setCustomerAdvisors]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { if (props.customerAdvisorsTeams === undefined) { props.loadCustomerAdvisorsTeams(); } }, []);
    
    useEffect(() => {
        if (!customerAdvisors.length) {
            updateCustomerAdvisors();
        }
    }, [props.customerAdvisorsTeams, props.teamScopeId, updateCustomerAdvisors, customerAdvisors]);
    
    useEffect(() => {
        updateCustomerAdvisors();
    }, [props.teamScopeId, updateCustomerAdvisors]);

    useEffect(() => {
        if (customerAdvisors.length) {
            const selectedCustomerAdvisors = customerAdvisors.filter(customerAdvisor => props.customerAdvisorIds?.some(id => id === customerAdvisor.id));
            setSelectedCustomerAdvisors(selectedCustomerAdvisors);
        }
    }, [customerAdvisors, props.customerAdvisorIds]);

    const onSelectionChange = (customerAdvisors: CustomerAdvisor[]) => props.onCustomerAdvisorsChanged && props.onCustomerAdvisorsChanged(customerAdvisors.map(customerAdvisor => customerAdvisor.id));
    const placeholder = props.placeholder || (props.placeholderKey && props.translate(props.placeholderKey).toString());

    if (!props.editMode) {
        return (
            <span>
                {selectedCustomerAdvisors.map(customerAdvisor => customerAdvisor.displayName).join(', ')}
            </span>
        )
    }

    return (
        <MultiSelect
            className={props.className}
            description={props.description}
            descriptionKey={props.descriptionKey}
            selectedItems={selectedCustomerAdvisors}
            items={customerAdvisors}
            displayValue={customerAdvisor => customerAdvisor.displayName}
            onSelectionChanged={onSelectionChange}
            placeHolder={placeholder}
        />
    );
};

const getCustomerAdvisors = (props: CustomerAdvisorsInputFieldProps & CustomerAdvisorsTeamsProps & UserProps) => {
    const teams = isAdmin(props) ? 
        props.customerAdvisorsTeams?.filter(team => !isValueSet(props.teamScopeId) || team.id === props.teamScopeId) : 
        props.customerAdvisorsTeams?.filter(team => team.id === props.userData.user?.teamId);
    return teams?.map(team => team.customerAdvisors).reduce((a, b) => a.concat(b), []) || [];
}

const mapStateToProps = (state: any) => ({
    ...state.userActionsReducer,
    ...state.customerAdvisorsTeamsReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({ ...CustomerAdvisorsTeamsActionsCreator }, dispatch);

export default connect<CustomerAdvisorsTeamsProps & UserProps, CustomerAdvisorsTeamsActions, CustomerAdvisorsInputFieldProps, any>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(CustomerAdvisorsInputField));
