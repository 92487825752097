import React, {FunctionComponent} from "react";
import {Translate} from "react-localize-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faMobile, faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import { MessageType } from "../../../models/MessageType";

interface SendMessageProps {
    email?: string | null;
    mobileNumber?: string | null;
    type?: MessageType;
    template: string;
}
const SendMessage: FunctionComponent<SendMessageProps> = ({
    email,
    mobileNumber,
    type,
    template
}) => {
    const hasEmail = email && email !== 'GDPR_GONE';
    const hasMobileNumber = mobileNumber && mobileNumber !== 'GDPR_GONE';
    
    if (
        (type === MessageType.Email && !hasEmail) ||
        (type === MessageType.SMS && !hasMobileNumber) ||
        (!hasEmail && !hasMobileNumber)
    ) {
        return null;
    }
    
    let messageIcon = faPaperPlane;
    switch (type) {
        case MessageType.SMS:
            messageIcon = faMobile;
            break;
        case MessageType.Email:
            messageIcon = faEnvelope;
            break;
    }

    return (
        <div className="send-message-template-post-action">
            <div className="description"><Translate id="SEND_MESSAGE"/></div>
            <FontAwesomeIcon icon={messageIcon}/>
            <span>{template}</span>
        </div>
    )
}
export default SendMessage;