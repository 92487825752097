import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, useState, ChangeEvent } from 'react';
import { Dropdown } from 'react-bootstrap';
import { defaultDisplayValueGetter, defaultKeyValueGetter } from '../helpers/defaultItemValueGetters';
import './generic-dropdown.css';

interface GenericDropdownProps<T> {
    keyValue?: (item: T) => string | number | null | undefined;
    displayValue?: (item: T) => string;
    style?: CSSProperties;
    dropdownId: string;
    items: T[];
    selectedItem?: T;
    selectedKey?: string | number | null | undefined;
    toggleClassName?: string;
    sortDisplayValues?: boolean;
    searchable?: boolean;
    filterPlaceholder?: string;
    readOnly?: boolean;
    renderUndefinedItems?: boolean;
    onSelectionChanged(item: T, key: string | number | null | undefined): void;
}

function GenericDropdown<T>(props: GenericDropdownProps<T>) {
    const [filter, setFilter] = useState('');
    const ref: React.RefObject<HTMLInputElement> = React.createRef();
    const firstItemRef: React.RefObject<any> = React.createRef();
    const focusFilter = () => setTimeout(() => {
        if (ref.current != null) {
            ref.current.focus();
        }
    }, 0);
    const getDisplayValue = (item: T | undefined) => (props.renderUndefinedItems ? true : item !== undefined && item !== null) ? (props.displayValue || defaultDisplayValueGetter)(item as T) : '';
    const getKey: (item: T) => string | number | null | undefined = (item: T) => (props.keyValue || defaultKeyValueGetter)(item);
    const currentItem = getCurrentItem(props.items, props.selectedItem, props.selectedKey, getKey);

    if (props.readOnly) {
        return <span>{getDisplayValue(currentItem)}</span>;
    }

    const filteredItems = props.items.filter((x) => {
        const displayValue = getDisplayValue(x);
        return typeof (displayValue) === 'string' ? displayValue.toLowerCase().indexOf(filter.toLowerCase()) > -1 : true;
    });

    const sortedItems = props.sortDisplayValues ? filteredItems.sort((i1, i2) => getDisplayValue(i1).localeCompare(getDisplayValue(i2))) : filteredItems;
    const searchable = props.searchable === true;

    const dropdownItems = sortedItems.map((si, idx) => {
        const selectionChangedHandler = () => {
            setFilter('');
            props.onSelectionChanged(si, getKey(si));
        };

        const displayValue = getDisplayValue(si);
        let displayValueElement = (<React.Fragment>{displayValue}</React.Fragment>);

        if (filter.length > 0) {
            const indexOfFilter = displayValue.toLowerCase().indexOf(filter.toLowerCase());
            const leftPart = displayValue.substring(0, indexOfFilter);
            const midPart = displayValue.substring(indexOfFilter, indexOfFilter + filter.length);
            const rightPart = displayValue.substring(indexOfFilter + filter.length);
            displayValueElement = (<React.Fragment>{leftPart}<b>{midPart}</b>{rightPart}</React.Fragment>);
        }

        return (
            <Dropdown.Item
                key={`${props.dropdownId}-${displayValue}-${getKey(si)}`}
                onClick={selectionChangedHandler}
                ref={idx === 0 ? firstItemRef : undefined}
            >
                {displayValueElement}
            </Dropdown.Item>
        );
    });

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => setFilter(event.target.value);

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if ((event.key === 'Enter' || event.keyCode === 13) && sortedItems.length > 0) {
            setTimeout(() => {
                if (firstItemRef.current !== null) {
                    firstItemRef.current.click();
                }
            }, 0);
        }
    };

    const placeholderText = props.filterPlaceholder === undefined ? '' : props.filterPlaceholder;

    const filterElements = searchable
        ? (
            <React.Fragment>
                <input
                    className="dropdown-input"
                    type="text"
                    value={filter}
                    onChange={onChangeHandler}
                    onKeyDown={onKeyDown}
                    placeholder={placeholderText}
                    ref={ref}
                />
                <hr />
            </React.Fragment>
        )
        : null;

    return (
        <Dropdown className="generic-dropdown" style={props.style} onClick={focusFilter}>
            <Dropdown.Toggle id={props.dropdownId} className={props.toggleClassName}>
                {getDisplayValue(currentItem)}
                <FontAwesomeIcon icon={faAngleDown} />
            </Dropdown.Toggle>
            <Dropdown.Menu id={`${props.dropdownId}-menu`}>
                {filterElements}
                {dropdownItems}
            </Dropdown.Menu>
        </Dropdown>
    );
}

function getCurrentItem<T>(
    items: T[],
    selectedItem: T | undefined,
    selectedKey: string | number | null | undefined,
    getKey: (item: T) => string | number | null | undefined) {
    if (selectedItem !== undefined) {
        return items.find((i) => i === selectedItem);
    }

    if (selectedKey !== undefined) {
        return items.find((i) => getKey(i) === selectedKey);
    }

    return undefined;
}

export default GenericDropdown;
