import {
    Campaign,
    CampaignInteraction,
    CampaignRecipient,
    CampaignRecipientsFilter, CampaignEngagement,
    CampaignStatus
} from "../../common/models/Campaign";
import {Action, Dispatch} from "redux";
import {getCampaign} from "../api/getCampaign";
import {getCampaignRecipientsPreview, GetCampaignRecipientsPreviewParams} from "../api/getCampaignRecipientsPreview";
import {TableState} from "../../common/models/TableState";
import {countCampaignRecipientsPreview} from "../api/countCampaignRecipientsPreview";
import {countCampaignRecipients} from "../api/countCampaignRecipients";
import {getCampaignRecipients, GetCampaignRecipientsParams} from "../api/getCampaignRecipients";
import {getCampaignEngagements} from "../api/getCampaignEngagements";

export const SET_CAMPAIGN = 'SET_CAMPAIGN';
export const SET_CAMPAIGN_RECIPIENTS = 'SET_CAMPAIGN_RECIPIENTS';
export const SET_CAMPAIGN_RECIPIENTS_COUNT = 'SET_CAMPAIGN_RECIPIENTS_COUNT';
export const EDIT_CAMPAIGN = 'EDIT_CAMPAIGN';
export const EDIT_RECIPIENTS_FILTER = 'EDIT_RECIPIENTS_FILTER';
export const SET_CAMPAIGN_ENGAGEMENTS = 'SET_CAMPAIGN_ENGAGEMENTS';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_ERROR = 'SET_ERROR';
export const SET_ERRORS = 'SET_ERRORS';

interface SetCampaign extends Action<typeof SET_CAMPAIGN> {
    payload: {
        campaign: Campaign
    }
}

interface EditCampaign extends Action<typeof EDIT_CAMPAIGN> {
    payload: {
        campaign: Partial<Campaign>,
        dirty?: boolean
    }
}

interface SetCampaignRecipients extends Action<typeof SET_CAMPAIGN_RECIPIENTS> {
    payload: {
        campaignRecipients: CampaignRecipient[]
    }
}

interface SetCampaignRecipientsCount extends Action<typeof SET_CAMPAIGN_RECIPIENTS_COUNT> {
    payload: {
        campaignRecipientsCount: number
    }
}

interface EditRecipientsFilter extends Action<typeof EDIT_RECIPIENTS_FILTER> {
    payload: {
        campaignRecipientsFilter: Partial<CampaignRecipientsFilter>
    }
}

interface SetCampaignEngagements extends Action<typeof SET_CAMPAIGN_ENGAGEMENTS> {
    payload: {
        campaignEngagements: CampaignEngagement[];
    }
}

interface SetActiveTab extends Action<typeof SET_ACTIVE_TAB> {
    payload: {
        activeTab: string;
    }
}

interface SetIsLoading extends Action<typeof SET_IS_LOADING> {
    payload: {
        isLoading: boolean;
    }
}

interface SetPageSize extends Action<typeof SET_PAGE_SIZE> {
    payload: {
        pageSize: number;
        tableName: 'recipients' | 'engagements';
    }
}

interface SetPageNumber extends Action<typeof SET_PAGE_NUMBER> {
    payload: {
        pageNumber: number;
        tableName: 'recipients' | 'engagements';
    }
}

interface SetError extends Action<typeof SET_ERROR> {
    payload: {
        name: string;
        value: boolean;
    }
}

interface SetErrors extends Action<typeof SET_ERRORS> {
    payload: {
        errors: {[name: string] : boolean}
    }
}

export type CampaignViewDataAction = 
    SetCampaign 
    | EditCampaign 
    | SetCampaignRecipients
    | SetCampaignRecipientsCount
    | EditRecipientsFilter 
    | SetCampaignEngagements
    | SetActiveTab
    | SetIsLoading
    | SetPageSize
    | SetPageNumber
    | SetError
    | SetErrors;

const setCampaign = (campaign: Campaign): SetCampaign => ({
    type: SET_CAMPAIGN,
    payload: {
        campaign
    }
});

const editCampaign = (campaign: Partial<Campaign>, dirty?: boolean): EditCampaign => ({
    type: EDIT_CAMPAIGN,
    payload: {
        campaign,
        dirty
    }
});

const setCampaignRecipients = (campaignRecipients: CampaignRecipient[]): SetCampaignRecipients => ({
    type: SET_CAMPAIGN_RECIPIENTS,
    payload: {
        campaignRecipients
    }
});

const setCampaignRecipientsCount = (campaignRecipientsCount: number): SetCampaignRecipientsCount => ({
    type: SET_CAMPAIGN_RECIPIENTS_COUNT,
    payload: {
        campaignRecipientsCount
    }
});

const editRecipientsFilter = (campaignRecipientsFilter: Partial<CampaignRecipientsFilter>): EditRecipientsFilter => ({
    type: EDIT_RECIPIENTS_FILTER,
    payload: {
        campaignRecipientsFilter
    }
});

const setCampaignEngagements = (campaignEngagements: CampaignEngagement[]): SetCampaignEngagements => ({
    type: SET_CAMPAIGN_ENGAGEMENTS,
    payload: {
        campaignEngagements
    }
});

const setActiveTab = (activeTab: string): SetActiveTab => ({
    type: SET_ACTIVE_TAB,
    payload: {
        activeTab
    }
});

const setIsLoading = (isLoading: boolean): SetIsLoading => ({
    type: SET_IS_LOADING,
    payload: {
        isLoading
    }
});

const setPageSize = (pageSize: number, tableName: 'recipients' | 'engagements'): SetPageSize => ({
    type: SET_PAGE_SIZE,
    payload: {
        pageSize,
        tableName
    }
});

const setPageNumber = (pageNumber: number, tableName: 'recipients' | 'engagements'): SetPageNumber => ({
    type: SET_PAGE_NUMBER,
    payload: {
        pageNumber,
        tableName
    }
});

const setError = (name: string, value: boolean): SetError => ({
    type: SET_ERROR,
    payload: {
        name,
        value
    }
});

const setErrors = (errors: {[name: string]: boolean}): SetErrors => ({
    type: SET_ERRORS,
    payload: {
        errors
    }
});

export interface CampaignViewDataActions {
    loadCampaign(campaignId: number);
    editCampaign(campaign: Partial<Campaign>, dirty?: boolean);
    loadCampaignRecipients(params: GetCampaignRecipientsParams);
    loadCampaignRecipientsPreview(params: GetCampaignRecipientsPreviewParams);
    editRecipientsFilter(params: Partial<CampaignRecipientsFilter>);
    loadCampaignEngagements(campaignId: number, tableState: TableState, interactionFilter?: CampaignInteraction);
    setActiveTab(activeTab: string);
    setPageSize(pageSize: number, tableName: 'recipients' | 'engagements');
    setPageNumber(pageNumber: number, tableName: 'recipients' | 'engagements');
    setError(name: string, value: boolean);
    setErrors(errors: {[name: string]: boolean});
}

export const CampaignViewDataActionCreators: CampaignViewDataActions = {
    loadCampaign: (campaignId: number) => {
        return (dispatch: Dispatch) => {
            dispatch(setIsLoading(true));
            getCampaign(campaignId).then(campaign => {
                dispatch(setCampaign(campaign));
                dispatch(setErrors({}));
                if (campaign.status === CampaignStatus.DRAFT) {
                    countCampaignRecipientsPreview({
                        campaignType: campaign.type,
                        filter: campaign.campaignRecipientsFilter
                    }).then(count => {
                        dispatch(setCampaignRecipientsCount(count));
                        dispatch(setIsLoading(false));
                    });
                } else {
                    dispatch(setIsLoading(false));
                }
            });
        }
    },
    editCampaign: (campaign: Partial<Campaign>, dirty = true) => {
        return (dispatch: Dispatch) => {
            dispatch(editCampaign(campaign, dirty));
        }
    },
    loadCampaignRecipients: (params: GetCampaignRecipientsParams) => {
        return async (dispatch: Dispatch, getState: any) => {
            if (getState().campaignViewDataReducer.isLoading) {
                return;
            }
            dispatch(setIsLoading(true));
            const recipients = await getCampaignRecipients(params);
            dispatch(setCampaignRecipients(recipients));
            const count = await countCampaignRecipients(params);
            dispatch(setCampaignRecipientsCount(count));
            dispatch(setIsLoading(false));
        }
    },
    loadCampaignRecipientsPreview: (params: GetCampaignRecipientsPreviewParams) => {
        return async (dispatch: Dispatch, getState: any) => {
            if (getState().campaignViewDataReducer.isLoading) {
                return;
            }
            dispatch(setIsLoading(true));
            const recipients = await getCampaignRecipientsPreview(params);
            dispatch(setCampaignRecipients(recipients));
            const count = await countCampaignRecipientsPreview(params);
            dispatch(setCampaignRecipientsCount(count));
            dispatch(setIsLoading(false));
        }
    },
    editRecipientsFilter: (filter: Partial<CampaignRecipientsFilter>) => {
        return (dispatch: Dispatch) => {
            dispatch(editRecipientsFilter(filter));
        }
    },
    loadCampaignEngagements: (campaignId: number, tableState: TableState, interactionFilter?: CampaignInteraction) => {
        return (dispatch: Dispatch) => {
            dispatch(setIsLoading(true));
            getCampaignEngagements(campaignId, {...tableState, interactionFilter}).then(engagements => {
                dispatch(setCampaignEngagements(engagements));
                dispatch(setIsLoading(false));
            });
        }
    },
    setActiveTab: (activeTab: string) => {
        return (dispatch: Dispatch) => {
            dispatch(setActiveTab(activeTab));
        }
    },
    setPageSize: (pageSize: number, tableName: 'recipients' | 'engagements') => {
        return (dispatch: Dispatch) => {
            dispatch(setPageSize(pageSize, tableName));
        }
    },
    setPageNumber: (pageNumber: number, tableName: 'recipients' | 'engagements') => {
        return (dispatch: Dispatch) => {
            dispatch(setPageNumber(pageNumber, tableName));
        }
    },
    setError: (name: string, value: boolean) => {
        return (dispatch: Dispatch) => {
            dispatch(setError(name, value));
        }
    },
    setErrors: (errors: {[name: string]: boolean}) => {
        return (dispatch: Dispatch) => {
            dispatch(setErrors(errors));
        }
    }
}