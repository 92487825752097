import React, {FunctionComponent} from "react";
import NumberFormatter from "../../../../../common/components/NumberFormatter";
import {useTranslate} from "../../../../../common/helpers/useTranslate";

interface SummaryProps {
    total: number;
}
const Summary: FunctionComponent<SummaryProps> = ({
    total
}) => {
    const translate = useTranslate();
    if (!total) {
        return null;
    }
    return (
        <span>
            {translate('TOTAL')}:&nbsp;
            <NumberFormatter
                value={total}
            />
        </span>
    )
}
export default Summary;