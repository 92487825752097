import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {forwardRef, useEffect, useRef} from 'react';
import InputField, {InputFieldProps} from './InputField';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import {formatDate, formatDateWithTime} from '../../helpers/dateFormatter';
import {LocalizeContextProps, Translate, withLocalize} from 'react-localize-redux';
import 'react-datepicker/dist/react-datepicker.css';
import enGB from 'date-fns/locale/en-GB';
import {FormControl, InputGroup, Overlay} from 'react-bootstrap';
import {useValidation, ValidationProps} from '../../helpers/useValidation';
import DropdownInputField from './DropdownInputField';
import {SelectableItem} from '../../models/SelectableItem';
import * as Popper from "@popperjs/core";
import moment from "moment";
import {isValueSet} from "../../helpers/isValueSet";

registerLocale('en-GB', enGB);
setDefaultLocale('en-GB'); // TODO: Replace with language from current culture

export enum DateTimeInputFieldKind {
    Date = 'date',
    LongDate = 'long-date',
    DateWithTime = 'date-time',
    YearWithMonth = 'year-month',
    Year = 'year'
}

interface DateTimeInputFieldProps extends InputFieldProps, LocalizeContextProps, ValidationProps {
    name: string;
    value: Date | string | null | undefined;
    kind?: DateTimeInputFieldKind;
    valuePostfix?: string;
    disabled?: boolean;
    popperPlacement?: Popper.Placement | undefined;
    defaultOpen?: boolean;
    showToday?: boolean;
    timeFrom?: number;
    timeTo?: number;
    onValueChanged?: (value: Date | null, name: string) => void;
    onBlur?: () => void;
}

interface CustomDateTimeInputProps {
    value?: string;
    disabled?: boolean;
    onClick?: any;
    onChange?: any;
    inputRef: React.RefObject<HTMLInputElement>;
    placeholderText?: string;
}

function DateTimeInputField<WrapperProps>(props: DateTimeInputFieldProps) {
    const [errorCode, internalError, ref, onTouched] = useValidation(props);
    const target = useRef<HTMLDivElement>(null);
    const calendar = useRef<HTMLElement>(null);

    useEffect(() => {
        const handleBlur = (e: FocusEvent) => {
            if (
                calendar.current?.contains(e.relatedTarget as HTMLElement) ||
                target.current?.contains(e.relatedTarget as HTMLElement)
            ) {
                return;
            }
            props.onBlur?.();
        };
        
        const input = ref.current;
        input?.addEventListener('blur', handleBlur);
        if (props.defaultOpen) {
            input?.focus();
        }

        return () => input?.removeEventListener('blur', handleBlur);
    }, [props, ref]);

    if (props.kind === DateTimeInputFieldKind.Year) {
        return renderYearPicker(props);
    }
    
    target.current?.addEventListener('focus', () => ref.current?.focus());

    return (
        <div ref={target} tabIndex={0}>
            <InputField<WrapperProps>
                inputRef={ref}
                className={props.className}
                afterContent={props.afterContent}
                descriptionKey={props.descriptionKey}
                description={props.description}
                editMode={props.editMode}
                clearable={isValueSet(props.value)}
                error={internalError}
                errorCode={errorCode}
                wrapper={props.wrapper}
                wrapperProps={props.wrapperProps}
                onClear={() => {
                    ref.current?.focus();
                    onTouched();
                    props.onValueChanged && props.onValueChanged(null, props.name)
                }}
            >
                {renderValue(props, target, ref, calendar, onTouched)}
            </InputField>
        </div>
    );
}

function renderValue(
    props: DateTimeInputFieldProps, 
    target: React.RefObject<HTMLDivElement>, 
    ref: React.RefObject<HTMLInputElement>,
    calendar: React.RefObject<HTMLElement>, 
    onTouched: () => void
) {
    if (!props.editMode) {
        if (props.value === undefined || props.value === null) {
            return '-';
        }

        if (props.kind && props.kind !== DateTimeInputFieldKind.DateWithTime) {
            return <span>{formatDate(props.value, props.translate)}</span>;
        }

        return <span>{formatDateWithTime(props.value, props.translate)}</span>;
    }

    const selected = props.value ? new Date(props.value) : null;
    const onValueChanged = (value: any) => {
        ref.current?.focus();
        onTouched();
        if (props.onValueChanged) {
            props.onValueChanged(value, props.name);
        }
    };

    const placeholderText = props.placeholderKey && props.translate(props.placeholderKey).toString();
    const timeInterval = 5;

    return (
        <DatePicker
            tabIndex={0}
            calendarClassName={props.showToday ? 'show-today' : ''}
            todayButton={props.showToday ? <Translate id="TODAY"/> : undefined}
            includeTimes={props.kind === DateTimeInputFieldKind.DateWithTime ? getIncludedTimes(props.timeFrom, props.timeTo, timeInterval) : []}
            startOpen={props.defaultOpen}
            selected={selected}
            onChange={onValueChanged}
            dateFormat={getTimeFormat(props.kind)}
            showTimeSelect={props.kind === DateTimeInputFieldKind.DateWithTime}
            timeFormat="HH:mm"
            timeIntervals={timeInterval}
            showMonthYearPicker={props.kind === DateTimeInputFieldKind.YearWithMonth}
            customInput={<CustomDateTimeInputRef inputRef={ref} placeholderText={placeholderText} />}
            disabled={props.disabled}
            onCalendarOpen={() => ref.current?.focus()}
            portalId="calendar"
            popperContainer={({children}) => (
                <Overlay ref={calendar} target={target} transition={false} show placement={props.popperPlacement || 'bottom-start'}>
                    {props => (
                        <div 
                            id="calendar"    
                            tabIndex={0}
                            ref={props.ref}
                            style={{...props.style, zIndex: 1000000}} 
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            {children}
                        </div>
                    )}
                </Overlay>
            )}
        />
    );
}

const CustomDateTimeInput = (props: CustomDateTimeInputProps, ref: any) => (
    <InputGroup ref={ref}>
        <FormControl
            type="text"
            value={props.value || ''}
            onChange={props.onChange}
            onClick={props.onClick}
            disabled={props.disabled}
            ref={props.inputRef as any}
            placeholder={props.placeholderText}
        />
        <InputGroup.Text onClick={props.disabled ? undefined : props.onClick}>
            <FontAwesomeIcon icon={faCalendarAlt}/>
        </InputGroup.Text>
    </InputGroup>
);

const CustomDateTimeInputRef = forwardRef(CustomDateTimeInput);

const currentYear = new Date().getFullYear();
const years = Array.apply(null, Array(currentYear - 1900 + 1)).map((_, i) => ({ id: currentYear - i, name: (currentYear - i).toString() } as SelectableItem<number>));

function renderYearPicker(props: DateTimeInputFieldProps) {
    const value = props.value ? new Date(props.value).getFullYear() : undefined;
    const onValueChanged = (year: string | number | null | undefined) =>
        props.onValueChanged && props.onValueChanged(new Date(year as number, 0, 1, 0, 0, 0, 0), props.name);
    return (
        <DropdownInputField
            {...props}
            value={value}
            onValueChanged={onValueChanged}
            items={years}
        />
    );
}

function getTimeFormat(kind?: DateTimeInputFieldKind) {
    switch (kind) {
        case DateTimeInputFieldKind.YearWithMonth:
            return 'MMMM yyyy';
        case DateTimeInputFieldKind.Date:
            return 'dd/M-yy';
        case DateTimeInputFieldKind.LongDate:
            return 'dd.MM.yyyy';
        default:
            return 'dd/M-yy HH:mm';
    }
}

function getIncludedTimes(from = 0, to = 24, interval = 5) {
    const includedTimes: Date[] = [];
    const m = moment(new Date()).set({hours: from, minutes: 0});
    const today = m.day();
    while (m.hours() < to && m.day() === today) {
        includedTimes.push(m.toDate());
        m.add(interval, 'minutes');
    }
    return includedTimes;
}

export default withLocalize(DateTimeInputField);
