import moment from "moment/moment";
import {OrderDirection} from "../../../../../common/models/TableState";
import {useTranslate} from "../../../../../common/helpers/useTranslate";
import {StageSorter} from "../../../../../common/components/pipeline/Stage";
import {ApplicationPipelineModel} from "../../../model/ApplicationPipelineModel";
import {PipelineStage} from "../../../model/PipelineStage";
import {LoanStatus} from "../../../../../common/models/LoanStatus";
import {Pipeline} from "../../../model/Pipeline";
import {Storage} from "../../../../../common/helpers/Storage";
import {useCallback, useEffect, useMemo, useState} from "react";

export interface ApplicationsPipelineSorting {
    [stage: PipelineStage['name']]: Pick<StageSorter<ApplicationPipelineModel>, 'id' | 'order'> | undefined;
}
export const useApplicationsPipelineSorting = (pipeline: Pipeline) => {
    const translate = useTranslate();
    const storageKey = useMemo(() => `${pipeline.name}.sorting`, [pipeline.name]);
    const [active, setActive] = useState<ApplicationsPipelineSorting | undefined>();
    
    useEffect(() => {
        if (active) {
            Storage.setItem<ApplicationsPipelineSorting>(storageKey, active);
        }
    }, [active, storageKey]);
    
    useEffect(() => {
        setActive(Storage.getItem<ApplicationsPipelineSorting>(storageKey) || undefined);
    }, [storageKey]);

    const onSortingChange = (sorting?: ApplicationsPipelineSorting) => setActive(prev => ({...(prev || {}), ...(sorting || {})}));
    
    const getStageSorter = useCallback((stage: PipelineStage) => {
        const sorters: StageSorter<ApplicationPipelineModel>[] = [
            {
                id: 'followUpDate',
                default: !active?.[stage.name] || active[stage.name]?.id === 'followUpDate',
                order: active?.[stage.name]?.id === 'followUpDate' ? active?.[stage.name]?.order : OrderDirection.Ascending,
                label: translate('FOLLOW_UP_DATE'),
                compare: compareDates('followUpDate'),
                editable: true
            },
            {
                id: 'submittedDate',
                default: active?.[stage.name]?.id === 'submittedDate',
                order: active?.[stage.name]?.id === 'submittedDate' ? active?.[stage.name]?.order : OrderDirection.Descending,
                label: translate('SUBMITTED_DATE'),
                compare: compareDates('submittedDate'),
                editable: true
            },
            {
                id: 'lastStatusChange',
                default: active?.[stage.name]?.id === 'lastStatusChange',
                order: active?.[stage.name]?.id === 'lastStatusChange' ? active?.[stage.name]?.order : OrderDirection.Descending,
                label: translate('LAST_STATUS_CHANGE'),
                compare: compareDates('lastStatusChange'),
                editable: true
            },
            {
                id: 'appliedAmount',
                default: active?.[stage.name]?.id === 'appliedAmount',
                order: active?.[stage.name]?.id === 'appliedAmount' ? active?.[stage.name]?.order : OrderDirection.Descending,
                label: translate('APPLICANTS_VIEW.TABLE_HEADERS.LOAN_AMOUNT'),
                compare: compareNumbers('appliedAmount'),
                editable: true
            },
            {
                id: 'bankAmount',
                default: active?.[stage.name]?.id === 'bankAmount',
                order: active?.[stage.name]?.id === 'bankAmount' ? active?.[stage.name]?.order : OrderDirection.Descending,
                label: translate('APPLICANTS_VIEW.TABLE_HEADERS.GRANTED_AMOUNT'),
                compare: compareNumbers('bankAmount'),
                editable: true
            },
            {
                id: 'applicationFormProgress',
                visible: (stage: PipelineStage) => stage.filter.status === LoanStatus.Incomplete,
                default: active?.[stage.name]?.id === 'applicationFormProgress',
                order: active?.[stage.name]?.id === 'applicationFormProgress' ? active?.[stage.name]?.order : OrderDirection.Descending,
                label: translate('APPLICANTS_VIEW.TABLE_HEADERS.FORM_PROGRESS'),
                compare: compareNumbers('applicationFormProgress'),
                editable: true
            }
        ];
        return sorters;
    }, [active, translate]);
    
    return [getStageSorter, active, onSortingChange] as const;
}

const compareDates = (key: keyof Pick<ApplicationPipelineModel, 'followUpDate' | 'submittedDate' | 'lastStatusChange'>) =>
    (order?: OrderDirection) => (a: ApplicationPipelineModel, b: ApplicationPipelineModel) =>
        moment(a[key]).diff(b[key]) * (order !== OrderDirection.Descending ? 1 : -1);

const compareNumbers = (key: keyof Pick<ApplicationPipelineModel, 'appliedAmount' | 'bankAmount' | 'applicationFormProgress'>) =>
    (order?: OrderDirection) => (a: ApplicationPipelineModel, b: ApplicationPipelineModel) =>
        ((a[key] || 0) - (b[key] || 0)) * (order !== OrderDirection.Descending ? 1 : -1);