import { ApplicationPipelineModel } from "./ApplicationPipelineModel";
import { PipelineFilterDefinition } from "./configuration/PipelineFilterDefinition";
import { PipelineStage } from "./PipelineStage";

export class Pipeline {
    name: string;
    filter: PipelineFilterDefinition;
    stages: PipelineStage[];
 
    constructor(name: string, filter: PipelineFilterDefinition, stages: PipelineStage[]) {
        this.name = name;
        this.filter = filter;
        this.stages = stages;
    }

    distributeApplications(applications: ApplicationPipelineModel[]): void {
        this.stages.forEach(stage => stage.clear());
        applications.forEach(application => {
            let bestStage: PipelineStage | undefined;
            let bestScore = 0;

            this.stages.forEach(stage => {
                const score = stage.calculateMatchScore(application);
                if (score > bestScore) {
                    bestScore = score;
                    bestStage = stage;
                }
            });

            if (!!bestStage) {
                bestStage.assignApplication(application);
            }
        });

        this.stages.forEach(stage => stage.completeAssignment());
    }
}