import React, {FunctionComponent, useMemo} from "react";
import {Translate} from "react-localize-redux";
import SubstatusesInputField from "../../input-fields/SubstatusesInputField";

interface RemoveSubstatusProps {
    current?: number[];
    toRemove: number[];
}
const RemoveSubstatus: FunctionComponent<RemoveSubstatusProps> = ({
    current,
    toRemove
}) => {
    const diff = useMemo(() => {
        return toRemove.filter(id => current?.includes(id))
    }, [toRemove, current]);

    if (!diff.length) {
        return null;
    }

    return (
        <div className="remove-substatus-template-post-action">
            <div className="description"><Translate id="REMOVE_SUBSTATUSES"/></div>
            <SubstatusesInputField substatusIds={diff}/>
        </div>
    )
}
export default RemoveSubstatus;