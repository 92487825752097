import {TranslateFunction} from 'react-localize-redux';
import {FollowUp} from '../models/FollowUp';
import mapEnumToSelectableItems from './mapEnumToSelectableItems';
import {getEnumTranslationKey} from './getTranslationKey';
import {Translate} from "./useTranslate";
import moment from 'moment';

export const getFollowUpTranslateKey = (followUp: string | number | null): string => getEnumTranslationKey(FollowUp, followUp, 'FOLLOW_UP', 'ALL');

const nameMapper = (translate: TranslateFunction | Translate) => (followUp: string | null) => translate(getFollowUpTranslateKey(followUp)).toString();

export const mapFollowUpsToSelectableItems = (translate: TranslateFunction | Translate) => mapEnumToSelectableItems<FollowUp | null>(FollowUp, nameMapper(translate));

export const convertFollowUpToPeriod = (followUp: Exclude<FollowUp, FollowUp.Custom>) => {
    const unit = getUnit(followUp);
    const modifier = getModifier(followUp);
    const from = moment().add(modifier, unit).startOf(unit)[unit === 'week' ? 'weekday' : 'toDate'](1).toISOString();
    const to = moment().add(modifier, unit).endOf(unit)[unit === 'week' ? 'weekday' : 'toDate'](7).toISOString();
    return [from, to] as const;
}

const getUnit = (followUp: Exclude<FollowUp, FollowUp.Custom>) => {
    switch (followUp) {
        case FollowUp.Yesterday:
        case FollowUp.Today:
        case FollowUp.Tomorrow:
            return 'day';
        case FollowUp.LastWeek:
        case FollowUp.ThisWeek:
        case FollowUp.NextWeek:
            return 'week';
    }
}

const getModifier = (followUp: Exclude<FollowUp, FollowUp.Custom>) => {
    switch (followUp) {
        case FollowUp.Yesterday:
        case FollowUp.LastWeek:
            return -1;
        case FollowUp.Today:
        case FollowUp.ThisWeek:
            return 0;
        case FollowUp.Tomorrow:
        case FollowUp.NextWeek:
            return 1;
    }
}
