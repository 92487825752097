import React, {FunctionComponent, useEffect, useState} from "react";
import "./ApplicationsPipelineView.css";
import {bindActionCreators, Dispatch} from "redux";
import {SetTitleActionCreator} from "../../../common/actions/SetTitleActionCreator";
import {connect} from "react-redux";
import {SetApplicationTitleActionProps} from "../../../common/interfaces/SetApplicationTitleActionProps";
import {
    getApplicationsPipelinesDefinitions,
    getMainBackgroundColorStyle
} from "../../../common/helpers/settingsHelpers";
import {SettingsProps} from "../../../common/interfaces/SettingsProps";
import {showToastMessage} from "../../../common/actions/ToastMessagesActionCreator";
import ApplicationsPipeline from "./ApplicationsPipeline/ApplicationsPipeline";
import {Pipeline} from "../model/Pipeline";
import GenericDropdown from "../../../common/components/GenericDropdown";
import {createPipeline} from "../model/PipelineFactory";
import {useTranslate} from "../../../common/helpers/useTranslate";
import ActionMenu from "../../applicants/components/ActionMenu";
import {useNavigate} from "react-router";
import {getApplicantsTotals} from "../../applicants/api/getApplicantsTotals";
import moment from "moment";
import {UserProps} from "../../../common/interfaces/UserProps";
import ApplicantsTotals from "../../applicants/components/ApplicantsTotals";

type ApplicationsPipelineViewStateProps = SettingsProps & UserProps;
type ApplicationsPipelineViewDispatchProps = SetApplicationTitleActionProps;

interface ApplicationsPipelineViewProps extends ApplicationsPipelineViewStateProps, ApplicationsPipelineViewDispatchProps {
    
}
const ApplicationsPipelineView: FunctionComponent<ApplicationsPipelineViewProps> = ({
    settingsData,
    userData,
    setTitle
}) => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const pipelineDefinitions = getApplicationsPipelinesDefinitions({settingsData});
    const applicationsPipelines = pipelineDefinitions.map(createPipeline);
    const storedPipeline = applicationsPipelines.find(pipeline => pipeline.name === localStorage['applications-pipeline']);
    const defaultPipeline: Pipeline | undefined = storedPipeline || applicationsPipelines[0];
    const [current, setCurrent] = useState<Pipeline | undefined>(defaultPipeline);
    const [totals, setTotals] = useState<any[]>([]);
    
    useEffect(() => {
        localStorage['applications-pipeline'] = current?.name;
        getApplicantsTotals({
            teamId: userData.user?.teamId,
            lastStatusChangeFrom: moment().startOf('month').toDate(),
            lastStatusChangeTo: moment().endOf('month').toDate(),
            products: current?.filter.products
        }).then(setTotals)
    }, [current, userData.user?.teamId]);

    useEffect(() => {
        setTitle(translate('APPLICATIONS_PIPELINE_VIEW.TITLE'));
    }, [setTitle, translate]);
    
    const onPipelineChange = (name?: string) => {
        setCurrent(applicationsPipelines.find(pipeline => pipeline.name === name) || defaultPipeline);
    }

    return (
        <main className="main-content" style={getMainBackgroundColorStyle({settingsData})}>
            <div className="jumbotron text-center bg-white flex-column justify-content-center row pb-4 pt-5 px-0 applications-pipeline-view">
                <ApplicantsTotals totals={totals}/>
                {applicationsPipelines.length ?
                    <GenericDropdown
                        key="pipelines"
                        dropdownId="pipelines"
                        displayValue={pipeline => pipeline.name}
                        selectedItem={applicationsPipelines.find(pipeline => pipeline.name === current?.name)}
                        items={applicationsPipelines}
                        onSelectionChanged={pipeline => setCurrent(pipeline)}
                    /> : translate('APPLICATIONS_PIPELINE_VIEW.NO_PIPELINES')
                }
                <div className="toolbar">
                    <ActionMenu newApplication={() => navigate('/applicant/application/new')}/>
                </div>
                {current ? <ApplicationsPipeline pipeline={current} onPipelineChange={onPipelineChange}/> : null}                
            </div>
        </main>
    )
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.userActionsReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...SetTitleActionCreator,
    showToastMessage
}, dispatch);

export default connect<ApplicationsPipelineViewStateProps, ApplicationsPipelineViewDispatchProps, {}, {}>(mapStateToProps, mapActionCreatorsToProps)(ApplicationsPipelineView);