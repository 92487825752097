import {LocalizeContextProps, withLocalize} from "react-localize-redux";
import React, {FunctionComponent, useMemo} from "react";
import {SettingsProps} from "../../../common/interfaces/SettingsProps";
import {connect} from "react-redux";
import {getApplicantsFilterBookmarks} from "../../../common/helpers/settingsHelpers";
import {ApplicantsViewFilter} from "./ApplicantsView";
import {bindActionCreators, Dispatch} from "redux";
import {
    ApplicantsViewTableActionCreators,
    ApplicantsViewTableActionProps
} from "../actions/ApplicantsViewDataActionCreators";
import {ApplicantsTableStateProps} from "../../../common/interfaces/ApplicantsTableStateProps";
import Bookmarks, {IBookmarks} from "../../../common/components/Bookmarks/Bookmarks";

type ApplicantsFilterBookmarksStateProps = SettingsProps & LocalizeContextProps & ApplicantsTableStateProps;
type ApplicantsFilterBookmarksDispatchProps = ApplicantsViewTableActionProps;
type ApplicantsFilterBookmarksProps = ApplicantsFilterBookmarksStateProps & ApplicantsFilterBookmarksDispatchProps;

const ApplicantsFilterBookmarks: FunctionComponent<ApplicantsFilterBookmarksProps> = (props) => {
    const currentFilter = props.tableState;
    const commonBookmarks: IBookmarks<ApplicantsViewFilter> = useMemo(() => getApplicantsFilterBookmarks(props), [props]);
    
    return (
        <Bookmarks<ApplicantsViewFilter>
            storageKey="applicants-filter"
            common={commonBookmarks}
            active={currentFilter}
            onActiveChange={props.setFilters}
        />
    )
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.applicantsViewDataReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...ApplicantsViewTableActionCreators
}, dispatch);

export default connect<ApplicantsFilterBookmarksStateProps, ApplicantsFilterBookmarksDispatchProps>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(ApplicantsFilterBookmarks));
