import React, { FunctionComponent } from "react";
import { MultiSelect } from "../../../../common/components/MultiSelect";
import {
    CustomersFilterProps,
    getFilterProp,
    renderLoanStatusFilter,
    renderBooleanInputFieldFilter,
    renderCustomerAdvisorsFilter,
    renderCustomerStatusesFilter,
    renderDateTimeInputFieldFilter,
    renderStringCollectionInputFilter,
    renderSubstatusesInputFieldFilter,
    renderPaidAmountFromFilter,
    renderPaidAmountToFilter,
    renderNominalInterestFromFilter,
    renderNominalInterestToFilter,
    renderUnsecuredDebtsFromFilter,
    renderUnsecuredDebtsToFilter,
    renderClawbackFilterType,
    renderClawbackIssuedFromFilter,
    renderClawbackIssuedToFilter,
    renderMinimumGrossYearlyIncomeFilter,
    renderPropertyEstimatedValueFilter,
    renderPropertyAreaMarketScoreFilter,
    renderLastStatusChangeFromFilter,
    renderLastStatusChangeToFilter,
    renderLastStatusChangeFilter,
    renderLastContactFilter
} from "./CustomersFilterContainer";
import { getLanguageItems } from "../../../../common/helpers/getLanguageItems";
import { ProductType } from "../../../../common/models/ProductType";
import { mapProductTypesToSelectableItems } from "../../../../common/helpers/productTypeFunctions";

const CustomersFilterPreview: FunctionComponent<CustomersFilterProps> = (props) => (
    <>
        <div className="edit">
            {renderLoanStatusFilter(props)}
            {renderLanguagesFilter(props)}
            {renderProductTypesFilter(props)}
            {renderIncludedSubstatusesFilter(props)}
            {renderExcludedSubstatusesFilter(props)}
            {renderMobileNumbersFilter(props)}
            {renderSkipActiveFollowUpsFilter(props)}
            {renderSubmittedFromFilter(props)}
            {renderSubmittedToFilter(props)}
            {renderLastStatusChangeFromFilter(props)}
            {renderLastStatusChangeToFilter(props)}
            {renderLastStatusChangeFilter(props)}
            {renderLastContactFilter(props)}
            {renderPaidAmountFromFilter(props)}
            {renderPaidAmountToFilter(props)}
            {renderNominalInterestFromFilter(props)}
            {renderNominalInterestToFilter(props)}
            {renderClawbackFilterType(props)}
            {renderClawbackIssuedFromFilter(props)}
            {renderClawbackIssuedToFilter(props)}
            {renderExcludeInkassoFilter(props)}
            {renderHasPropertyFilter(props)}
            {renderPropertyEstimatedValueFilter(props)}
            {renderPropertyAreaMarketScoreFilter(props)}
            {renderMinimumGrossYearlyIncomeFilter(props)}
            {renderUnsecuredDebtsFromFilter(props)}
            {renderUnsecuredDebtsToFilter(props)}
        </div>
        <div
            className="edit"
            style={{ marginTop: '12px' }}
        >
            {renderCustomerAdvisorsFilter(props)}
            {renderCustomerStatusesFilter(props)}
        </div>
    </>
);

const renderLanguagesFilter = (props: CustomersFilterProps) => {
    const languages = getFilterProp<string[]>('languages', props) || [];
    const onLanguagesChanged = (languages: string[]) => props.onChange({ languages });
    const languagesItems = getLanguageItems(props.translate, true, false);

    return (
        <div>
            <MultiSelect
                descriptionKey="LANGUAGE.LABEL"
                className={languages.length ? 'filter-active' : ''}
                selectedItems={languagesItems.filter(item => languages.some(language => item.id === language))}
                items={languagesItems}
                onSelectionChanged={items => onLanguagesChanged(items.map(item => item.id as string))}
                placeHolder={props.translate('FILTER_LANGUAGES').toString()}
            />
        </div>
    );
}

const renderProductTypesFilter = (props: CustomersFilterProps) => {
    const productTypes = getFilterProp<ProductType[]>('productType', props) || [];
    const onProductTypesChanged = (productTypes: ProductType[]) => props.onChange({ productType: productTypes });
    const productTypesItems = mapProductTypesToSelectableItems(props.translate, true, false);

    return (
        <div>
            <MultiSelect
                descriptionKey="PRODUCT_TYPE"
                className={productTypes.length ? 'filter-active' : ''}
                selectedItems={productTypesItems.filter(item => productTypes.some(productType => item.id === productType))}
                items={productTypesItems}
                onSelectionChanged={items => onProductTypesChanged(items.map(item => item.id as ProductType))}
                placeHolder={props.translate('FILTER_PRODUCT_TYPES').toString()}
            />
        </div>
    );
};

const renderIncludedSubstatusesFilter = (props: CustomersFilterProps) =>
    renderSubstatusesInputFieldFilter('includedSubstatuses', 'INCLUDED_SUBSTATUSES', props);

const renderExcludedSubstatusesFilter = (props: CustomersFilterProps) =>
    renderSubstatusesInputFieldFilter('excludedSubstatuses', 'EXCLUDED_SUBSTATUSES', props);

const renderSkipActiveFollowUpsFilter = (props: CustomersFilterProps) =>
    renderDateTimeInputFieldFilter('skipActiveFollowUps', 'CAMPAIGNS_VIEW.SKIP_ACTIVE_FOLLOW_UPS', props);

const renderSubmittedFromFilter = (props: CustomersFilterProps) =>
    renderDateTimeInputFieldFilter('submittedFrom', 'SUBMITTED_FROM', props);

const renderSubmittedToFilter = (props: CustomersFilterProps) =>
    renderDateTimeInputFieldFilter('submittedTo', 'SUBMITTED_TO', props);

const renderExcludeInkassoFilter = (props: CustomersFilterProps) =>
    renderBooleanInputFieldFilter('excludeInkasso', 'CAMPAIGNS_VIEW.EXCLUDE_INKASSO', props);

const renderHasPropertyFilter = (props: CustomersFilterProps) =>
    renderBooleanInputFieldFilter('hasProperty', 'PROPERTY_REQUIRED', props);

const renderMobileNumbersFilter = (props: CustomersFilterProps) =>
    renderStringCollectionInputFilter('mobileNumbers', 'MOBILE_NUMBERS', props);

export default CustomersFilterPreview;