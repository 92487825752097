import Item from "./Item";
import Title from "./Title";
import Summary from "./Summary";

interface IApplicationsPipelineStage {
    Title: typeof Title;
    Item: typeof Item;
    Summary: typeof Summary;
}

const ApplicationsPipelineStage: IApplicationsPipelineStage = {
    Title,
    Item,
    Summary
}
export default ApplicationsPipelineStage;