import React, { FunctionComponent, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getPropertyInformation } from '../../api/getPropertyInformation';
import { PropertyInformation } from '../../models/PropertyInformation';
import './property-information-modal.css';
import { Translate } from 'react-localize-redux';
import { putPropertyInternalDetails } from '../../api/putPropertyInternalDetails';
import PropertiesInfomationModalPropertyCard from './PropertiesInfomationModalPropertyCard';
import { ConsumerLoan } from '../../models/ConsumerLoan';
import { formatDateWithTime } from '../../../../common/helpers/dateFormatter';

interface PropertiesInformationModalProps {
    show: boolean;
    application: ConsumerLoan;
    onUpdate: () => void;
    onHide: () => void;
}

interface PropertiesInformationState {
    properties: PropertyInformation[] | null;
    editedProperty: PropertyInformation | null;
}

const PropertiesInformationModal: FunctionComponent<PropertiesInformationModalProps> = (props) => {
    const [state, setState] = useState<PropertiesInformationState>({ properties: null, editedProperty: null });
    const setEditedProperty = (property: PropertyInformation | null) => setState({ properties: state.properties, editedProperty: property });

    useEffect(() => {
        setEditedProperty(null);

        if (!props.show) {
            return;
        }

        loadData(props, setState);
    }, [props.show, props.application]); // eslint-disable-line react-hooks/exhaustive-deps
    const save = (p: PropertyInformation) => saveProperty(p, props, setState);

    const lastUpdate = state.properties !== null
        ? formatDateWithTime(state.properties[0].lastUpdate)
        : null;

    return (
        <Modal show={props.show} centered={true} size='lg' onHide={props.onHide}>
            <Modal.Header>
                <Modal.Title>
                    <Translate id="PROPERTIES_MODAL.PROPERTIES" /> (<Translate id="PROPERTIES_MODAL.LAST_UPDATE" /> {lastUpdate})
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="property-table">
                {renderProperties(state, setEditedProperty, save)}
            </Modal.Body>
            <Modal.Footer />
        </Modal>
    );
}

const renderProperties = (state: PropertiesInformationState, setEditedProperty: (p: PropertyInformation | null) => void, saveProperty: (p: PropertyInformation) => Promise<string[]>) =>
    state.properties?.map(p => <PropertiesInfomationModalPropertyCard key={p.id} property={p} editedProperty={state.editedProperty} setEditedProperty={setEditedProperty} saveProperty={saveProperty} />);

function loadData(props: React.PropsWithChildren<PropertiesInformationModalProps>, setState: React.Dispatch<React.SetStateAction<PropertiesInformationState>>) {
    const socialSecurityNumbers: Array<string> = [];
    socialSecurityNumbers.push(props.application.applicant.socialSecurityNumber);
    if (props.application.coApplicant !== undefined && props.application.coApplicant !== null) {
        socialSecurityNumbers.push(props.application.coApplicant.socialSecurityNumber);
    }

    getPropertyInformation(socialSecurityNumbers).then(properties => setState({ properties, editedProperty: null }));
}

const saveProperty = (property: PropertyInformation, props: React.PropsWithChildren<PropertiesInformationModalProps>, setState: React.Dispatch<React.SetStateAction<PropertiesInformationState>>) =>
    putPropertyInternalDetails(
        property.id,
        {
            value: property.value,
            insurance: property.insurance
        }
    ).then((result) => { loadData(props, setState); props.onUpdate(); return result; });

export default PropertiesInformationModal;
