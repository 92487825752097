import React, {FunctionComponent, SetStateAction, useEffect, useState} from 'react';
import {Translate} from 'react-localize-redux';
import {getConsumerLoanLogs} from '../../api/getConsumerLoanLogs';
import {ConsumerLoanCustomerCommunicationLog, ConsumerLoanLog} from '../../models/ConsumerLoanLog';
import {ConsumerLoanLogType} from '../../models/ConsumerLoanLogType';
import './logs-and-comments.css';
import LogsAndCommentsFilter from './LogsAndCommentsFilter';
import LogsAndCommentsForm from './LogsAndCommentsForm';
import LogsAndCommentsTable from './LogsAndCommentsTable';
import {postComment} from '../../api/postComment';
import {logsAndCommentsFiltersStorage} from '../../helpers/logsAndCommentsFiltersStorage';
import {showToastMessage} from '../../../../common/actions/ToastMessagesActionCreator';
import {bindActionCreators} from 'redux';
import {ReceivedMessagesActionCreator} from '../../../../common/actions/ReceivedMessagesActionCreator';
import {connect} from 'react-redux';
import {ReceivedMessagesDispatchProps} from '../../../../common/interfaces/ReceivedMessagesProps';
import {CommentNotification} from '../../models/CommentNotification';
import {markAsRead} from '../../api/markAsRead';
import {ConsumerLoanLogMessagingEventType} from '../../models/ConsumerLoanLogMessagingEventType';
import {PostCommentActionsProps} from "../../../../common/components/CommentTemplates/CommentTemplates";

interface LogsAndCommentsProps extends Pick<PostCommentActionsProps, 'onCommunicationTemplateChange' | 'onSubstatusesChange' | 'onDenyApplication'>{
    personId: number;
    loanId?: number;
    includeCoApplicant: boolean;
    translate: (key: string) => string;
    showToastMessage: typeof showToastMessage;
    onFormInputChanged: (isEmpty: boolean) => void;
}

interface LogsAndCommentsState {
    logs: ConsumerLoanLog[];
    logCount: number;
    isDataReady: boolean;
    filterComments: boolean;
    filterEvents: boolean;
    filterChanges: boolean;
    filterDocuments: boolean;
    pageSize: number;
    pageNumber: number;
    orderDirection: number;
    pendingRequest: boolean;
}

const LogsAndComments: FunctionComponent<LogsAndCommentsProps & ReceivedMessagesDispatchProps> = (props) => {
    const { filterComments, filterEvents, filterChanges, filterDocuments } = logsAndCommentsFiltersStorage.get();
    const [state, setState] = useState<LogsAndCommentsState>({
        logs: [],
        logCount: 0,
        isDataReady: false,
        filterComments,
        filterEvents,
        filterChanges,
        filterDocuments,
        pageSize: 10,
        pageNumber: 0,
        orderDirection: 1,
        pendingRequest: false
    });
    
    useEffect(() => {
        if (props.personId !== undefined) {
            setState(state => ({...state, isDataReady: false }));
            getConsumerLoansBasedOnPropsAndState(props, state, setState);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (!state.isDataReady || state.pendingRequest) {
            return;
        }
        if (props.personId !== undefined) {
            setState((state: LogsAndCommentsState) => ({...state, pageNumber: 0 }));
            getConsumerLoansBasedOnPropsAndState(props, state, setState);
        }
    }, [props.loanId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!state.isDataReady || state.pendingRequest) {
            return;
        }
        getConsumerLoansBasedOnPropsAndState(props, state, setState);
    }, [state.pageNumber, state.pageSize, state.orderDirection]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!state.isDataReady || state.pendingRequest) {
            return;
        }
        const { filterComments, filterEvents, filterChanges, filterDocuments } = state;
        logsAndCommentsFiltersStorage.set({ filterComments, filterEvents, filterChanges, filterDocuments });
        getConsumerLoansBasedOnPropsAndState(props, state, setState);
    }, [state.filterComments, state.filterEvents, state.filterChanges, state.filterDocuments]); // eslint-disable-line react-hooks/exhaustive-deps
    
    if (!state.isDataReady) {
        return (
            <div className="right-column column-card">
                <Translate id="LOGS_AND_COMMENTS" />
            </div>
        );
    }

    const onFilterCommentsClicked = (): void => setState((state: LogsAndCommentsState) => ({...state, filterComments: !state.filterComments }));
    const onFilterEventsClicked = (): void => setState((state: LogsAndCommentsState) => ({...state, filterEvents: !state.filterEvents }));
    const onFilterChangesClicked = (): void => setState((state: LogsAndCommentsState) => ({...state, filterChanges: !state.filterChanges }));
    const onFilterFilesClicked = (): void => setState((state: LogsAndCommentsState) => ({...state, filterDocuments: !state.filterDocuments }));

    return (
        <div className="right-column column-card logs-and-comments">
            <div className="card-heading">
                <Translate id="LOGS_AND_COMMENTS" />
            </div>
            <LogsAndCommentsForm
                onSaveComment={(text, notification, file) => saveComment(props, state, setState, text, notification, file)}
                onFormInputChanged={props.onFormInputChanged}
                onSubstatusesChange={props.onSubstatusesChange}
                onCommunicationTemplateChange={props.onCommunicationTemplateChange}
                onDenyApplication={props.onDenyApplication}
            />
            <LogsAndCommentsFilter
                filterChanges={state.filterChanges}
                filterComments={state.filterComments}
                filterEvents={state.filterEvents}
                filterDocuments={state.filterDocuments}
                pendingRequest={state.pendingRequest}
                onFilterChangesClicked={onFilterChangesClicked}
                onFilterCommentsClicked={onFilterCommentsClicked}
                onFilterEventsClicked={onFilterEventsClicked}
                onFilterDocumentsClicked={onFilterFilesClicked}
                onRefreshClick={() => getConsumerLoansBasedOnPropsAndState(props, state, setState)}
            />
            <LogsAndCommentsTable
                logs={state.logs}
                logCount={state.logCount}
                pageSize={state.pageSize}
                pageNumber={state.pageNumber}
                orderDirection={state.orderDirection}
                minRowCount={10}
                onOrderDirectionChanged={orderDirection => orderDirectionChanged(setState, orderDirection)}
                onPagePicked={pageNumber => pagePicked(setState, pageNumber)}
                onPageSizePicked={pageSize => pageSizePicked(setState, pageSize)}
                onRowDoubleClick={log => onRowDoubleClicked(props, state, setState, log)}
            />
        </div>
    );
}

function orderDirectionChanged(setState: React.Dispatch<SetStateAction<LogsAndCommentsState>>, orderDirection: number): void {
    setState(state => ({...state, orderDirection }));
}

function pageSizePicked(setState: React.Dispatch<SetStateAction<LogsAndCommentsState>>, pageSize: number): void {
    setState(state => ({...state, pageSize, pageNumber: 0 }));
}

function pagePicked(setState: React.Dispatch<SetStateAction<LogsAndCommentsState>>, pageNumber: number): void {
    setState(state => ({...state, pageNumber }));
}

async function saveComment(
    props: LogsAndCommentsProps, 
    state: LogsAndCommentsState, 
    setState: React.Dispatch<SetStateAction<LogsAndCommentsState>>, 
    commentText: string, 
    notification: CommentNotification, 
    files: any[]
) {
    const result = await postComment(props.personId, props.loanId, commentText, files, notification);

    if (result.success) {
        props.showToastMessage('success', props.translate('SAVE_COMMENT'), props.translate('SAVE_COMMENT_SUCCESS'), 'SAVE_COMMENT');
        getConsumerLoansBasedOnPropsAndState(props, state, setState);
    } else {
        props.showToastMessage('error', props.translate('SAVE_COMMENT'), result.errors.map((e) => props.translate(e.code)), 'SAVE_COMMENT');
    }
}

function getLogTypeFilters(state: LogsAndCommentsState): number[] {
    const logTypeFilters: number[] = [];

    if (state.filterComments) {
        logTypeFilters.push(ConsumerLoanLogType.Comment);
    }

    if (state.filterEvents) {
        logTypeFilters.push(ConsumerLoanLogType.Event);
        logTypeFilters.push(ConsumerLoanLogType.CustomerCommunication);
    }

    if (state.filterChanges) {
        logTypeFilters.push(ConsumerLoanLogType.Change);
    }

    if (state.filterDocuments) {
        logTypeFilters.push(ConsumerLoanLogType.Document);
    }

    return logTypeFilters;
}

function getConsumerLoansBasedOnPropsAndState(props: LogsAndCommentsProps, state: LogsAndCommentsState, setState: React.Dispatch<SetStateAction<LogsAndCommentsState>>): void {
    setState(state => ({...state, pendingRequest: true }));
    getConsumerLoanLogs(
        props.personId,
        props.loanId,
        props.includeCoApplicant,
        state.pageNumber,
        state.pageSize,
        state.orderDirection,
        getLogTypeFilters(state))
        .then((result: { loanLogs: ConsumerLoanLog[], loanLogsCount: number }) => {
            if (result.loanLogs.length === 0 && state.pageNumber > 0 && result.loanLogsCount > 0) {
                setState(() => ({...state, pageNumber: 0 }));
            } else {
                setState(() => ({
                    ...state,
                    logs: result.loanLogs,
                    logCount: result.loanLogsCount,
                    pendingRequest: false,
                    isDataReady: true
                }));
            }
        });
}

function onRowDoubleClicked(props: LogsAndCommentsProps, state: LogsAndCommentsState, setState: React.Dispatch<SetStateAction<LogsAndCommentsState>>, log: ConsumerLoanLog): void {
    if (log.logType === ConsumerLoanLogType.CustomerCommunication
            && (log as ConsumerLoanCustomerCommunicationLog).eventType === ConsumerLoanLogMessagingEventType.CommunicationFromClient
            && !(log as ConsumerLoanCustomerCommunicationLog).read) {
        markAsRead(log.id)
            .then(() => getConsumerLoansBasedOnPropsAndState(props, state, setState));
    }
}

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators({
    ...ReceivedMessagesActionCreator
} as any, dispatch);

export default connect<{}, ReceivedMessagesDispatchProps, LogsAndCommentsProps, any>(null, mapActionCreatorsToProps)(LogsAndComments);
