import React, {FunctionComponent, ReactNode, useState} from "react";
import "./FollowUpInlineInputField.css";
import DateTimeInputField, {DateTimeInputFieldKind} from "../input-fields/DateTimeInputField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faBellSlash} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../Tooltip";
import {Translate} from "react-localize-redux";


interface FollowUpInlineInputFieldProps {
    followUpDate?: Date | string | null;
    hasReminder?: boolean;
    editMode?: boolean;
    descriptionKey?: string;
    description?: ReactNode | string;
    onChange?: (followUp?: Date | null) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    onReminderClick?: () => void;
}

const FollowUpInlineInputField: FunctionComponent<FollowUpInlineInputFieldProps> = ({
    followUpDate,
    hasReminder,
    editMode,
    descriptionKey,
    description,
    onChange,
    onFocus,
    onBlur,
    onReminderClick
}) => {
    const [focused, setFocused] = useState<boolean>(false);

    const reminderIcon = (
        <Tooltip content={<Translate id={hasReminder ? 'REMINDERS_ENABLED' : 'REMINDERS_DISABLED'} />}>
            <FontAwesomeIcon
                style={{color: '#707070'}}
                className="cursor-pointer"
                icon={hasReminder ? faBell : faBellSlash}
                onClick={(e) => {
                    onReminderClick?.();
                    e.preventDefault();
                    e.stopPropagation();
                }}/>
        </Tooltip>
    );

    return (
        <div
            className="follow-up-inline-input-field cursor-pointer"
            onClick={() => {
                if (!focused) {
                    onFocus?.();
                    setFocused(true);
                }
            }}
        >
            <DateTimeInputField
                name="followUpDate"
                description={description}
                descriptionKey={descriptionKey}
                value={followUpDate}
                editMode={editMode}
                afterContent={followUpDate && !editMode ? reminderIcon : null}
                showToday
                defaultOpen
                kind={DateTimeInputFieldKind.DateWithTime}
                timeFrom={8}
                timeTo={20}
                onBlur={() => {
                    if (focused) {
                        onBlur?.();
                        setFocused(false);
                    }
                }}
                onValueChanged={onChange}
            />
        </div>
    )
}
export default FollowUpInlineInputField;