import {AxiosPromise} from "axios";
import {AxiosClientFactory} from '../../../common/helpers/AxiosClientFactory';
import {ApplicationPipelineModel} from "../model/ApplicationPipelineModel";
import {ApplicationsPipelineFilter} from "../components/ApplicationsPipeline/helpers/useApplicationsPipelineFilters";

export const getApplications = (params: ApplicationsPipelineFilter): AxiosPromise<ApplicationPipelineModel[]> => {
    return AxiosClientFactory
        .getInstance()
        .get<ApplicationPipelineModel[]>('/api/applications/pipelineView', { params});
};
